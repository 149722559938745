import React from 'react';
import ArticleCard from '../../Components/Target/NewsTab/ArticleCard';
import { InformationSquareIcon } from '@hugeicons/react';
import SectionCard from '../../Components/Target/OverviewTab/SectionCard';
import NoData from '../../Components/Target/NoData';

const RecentNewsPage = ({ data }) => {
	const socialMediaLst = [
		{
			label: 'LinkedIn',
			data:
				typeof data?.news?.social_media?.linkedin_url != 'string'
					? data?.news?.social_media?.linkedin_url?.join(', ')
					: data?.news?.social_media?.linkedin_url,
		},
		{
			label: 'X',
			data:
				typeof data?.news?.social_media?.twitter_url != 'string'
					? data?.news?.social_media?.twitter_url?.join(', ')
					: data?.news?.social_media?.twitter_url,
		},
		{
			label: 'Facebook',
			data:
				typeof data?.news?.social_media?.facebook_url != 'string'
					? data?.news?.social_media?.facebook_url?.join(', ')
					: data?.news?.social_media?.facebook_url,
		},
		{
			label: 'Instagram',
			data:
				typeof data?.news?.social_media?.instagram_url != 'string'
					? data?.news?.social_media?.instagram_url?.join(', ')
					: data?.news?.social_media?.instagram_url,
		},
		{
			label: 'YouTube',
			data:
				typeof data?.news?.social_media?.youtube_url != 'string'
					? data?.news?.social_media?.youtube_url?.join(', ')
					: data?.news?.social_media?.youtube_url,
		},
		{
			label: 'GitHub',
			data:
				typeof data?.news?.social_media?.github_url != 'string'
					? data?.news?.social_media?.github_url?.join(', ')
					: data?.news?.social_media?.github_url,
		},
		{
			label: 'TikTok',
			data:
				typeof data?.news?.social_media?.tiktok_url != 'string'
					? data?.news?.social_media?.tiktok_url?.join(', ')
					: data?.news?.social_media?.tiktok_url,
		},
		{
			label: 'Pinterest',
			data:
				typeof data?.news?.social_media?.pinterest_url != 'string'
					? data?.news?.social_media?.pinterest_url?.join(', ')
					: data?.news?.social_media?.pinterest_url,
		},
	];
	const followersLst = [
		{
			label: 'LinkedIn Followers',
			data: data?.news?.followers?.followers_count_linkedin,
		},
		{
			label: 'X (Twitter) Followers',
			data: data?.news?.followers?.followers_count_twitter,
		},
	];
	return (
		<div className='flex flex-col w-full h-[1056px]  justify-start items-center bg-black relative px-16 py-24'>
			<div className='text-white flex flex-col justify-start items-start gap-3 w-full'>
				<h3 className='text-white font-bold text-[60px]'>RECENT NEWS</h3>
				{data?.news?.news_articles?.length > 0 ? (
					<div className='w-full h-auto flex flex-col gap-5'>
						{data?.news?.news_articles?.slice(0, 3).map((info, x) => (
							<ArticleCard
								key={x}
								headline={info?.headline}
								date={info?.published_date}
								news={info?.summary}
								url={info?.article_url}
							/>
						))}
					</div>
				) : (
					<NoData />
				)}
				{data?.news?.social_media && data?.news?.followers && (
					<div className='w-full  flex flex-row justify-between gap-16'>
						<div className='w-1/2 flex flex-col gap-3'>
							{data?.news?.social_media?.data && (
								<>
									<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
										<InformationSquareIcon
											size={14}
											color='#5092E0'
											variant='stroke'
											type='rounded'
										/>
										Social Media
									</div>
									{socialMediaLst.map((info) => (
										<SectionCard
											key={info.label}
											label={info.label}
											data={info.data}
										/>
									))}
								</>
							)}
						</div>
						<div className='w-1/2 h-full flex flex-col gap-3'>
							{data?.news?.followers?.data && (
								<>
									<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
										<InformationSquareIcon
											size={14}
											color='#5092E0'
											variant='stroke'
											type='rounded'
										/>
										Followers
									</div>
									{followersLst.map((info) => (
										<SectionCard
											key={info.label}
											label={info.label}
											data={info.data}
										/>
									))}
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecentNewsPage;
