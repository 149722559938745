import React, { useMemo, useState } from "react";
import "../../TargetCharts/index.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import SectionCard from "../OverviewTab/SectionCard";
import { InformationSquareIcon, MoneySendSquareIcon } from "@hugeicons/react";
import { useOutletContext } from "react-router-dom";

import ReviewBarChart from "../../TargetCharts/ReviewBarCharts";
import ReviewDistChart from "../../TargetCharts/ReviewDistCharts";
import YearDropdown from "../../TargetCharts/YearDropdown";
import NoData from "../NoData";

const ReviewTab = () => {
  const { product_review } = useOutletContext();
  const [year, setYear] = useState(new Date().getFullYear());
  const pricingData = [
    {
      label: "Online Product Reviews",
      data: product_review?.online_review,
    },
    {
      label: "Sources",
      data: product_review?.sources,
    },
  ];

  function groupDataByYear(data) {
    return data?.reduce((acc, item) => {
      const { year, ...rest } = item; // Extract year and other data
      if (!acc[year]) {
        acc[year] = []; // Initialize an array for the year if not exists
      }
      acc[year].push(rest); // Push the rest of the data into the corresponding year
      return acc;
    }, {});
  }

  const data = groupDataByYear(product_review?.product_reviews_score_by_month);

  return product_review?.data ? (
    <div className="w-full 2xl:h-full h-auto flex flex-col 2xl:flex-row justify-between">
      <div className="w-full 2xl:w-3/5 h-full flex flex-col gap-5">
        <div className="w-full flex flex-row justify-between items-end">
          <div className="w-full h-auto flex flex-col justify-center gap-2">
            <p className="text-gray-500 text-xs font-normal">
              Average Score of Product Reviews:
            </p>
            <div className="w-full flex flex-row gap-10 items-center">
              <p className="text-[--dark-text] text-2xl font-medium leading-relaxed">
                {product_review.avg_score.toFixed(2)}{" "}
                <span className="text-[--dark-text] font-light text-lg">
                  / 5.0
                </span>
              </p>
              <p className="text-green-400 text-xs font-normal">
                {product_review.score_change.current}%{" "}
                <span className="text-gray-500 font-light text-xs tracking-[1.6px]">
                  VS LAST MONTH
                </span>
              </p>
            </div>
          </div>
          <div className="w-auto h-auto flex flex-row gap-5 items-center pb-3">
            <YearDropdown year={year} setYear={setYear} data={data} />
          </div>
        </div>
        {data[year]?.length > 0 ? (
          <div id="reviewMonthlyChart" className="w-full min-h-[500px]">
            <ReviewBarChart data={data} year={year} />
          </div>
        ) : (
          <div className="w-full h-full min-h-[500px]">
            <NoData />
          </div>
        )}
      </div>
      <div className="w-full 2xl:w-1/3 h-full flex flex-col gap-3">
        <div className="w-full text-xs text-[--dark-text] flex gap-1.5">
          <InformationSquareIcon
            size={14}
            color="#5092E0"
            variant="stroke"
            type="rounded"
          />
          Additional Intelligence
        </div>
        {pricingData.map((info) => (
          <SectionCard key={info.label} label={info.label} data={info.data} />
        ))}

        {Object?.entries(product_review.product_reviews_score_distribution)
          ?.length > 0 && (
          <div className="mt-5 text-white rounded-lg shadow-lg w-full max-w-4xl mx-auto">
            <p className="text-gray-400 text-xs">
              Total - {product_review.product_reviews_distribution_count}
            </p>
            <h3 className="text-sm font-semibold mb-2">
              Review Score Distribution
            </h3>
            <ReviewDistChart
              data={product_review.product_reviews_score_distribution}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <NoData />
  );
};

export default ReviewTab;
