import React, { PureComponent, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Bar,
  BarChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const TooltipContent = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#1E1B39] text-white text-sm p-2 rounded-md min-w-20 text-center">
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }
};

const formatMonth = (month) => month.substring(0, 3);

const ReviewBarChart = ({ data, year }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <ResponsiveContainer width="100%" height="70%" minHeight={300}>
      <BarChart
        width={500}
        height={300}
        data={data[year]}
        barCategoryGap={"20%"}
        margin={{
          top: 20,
          right: 30,
          left: 16,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={formatMonth}
          tickLine={false}
          tickMargin={10}
          tick={{ fill: "#615E83", fontSize: 12 }}
          stroke="#E5E5EF"
        />
        <YAxis
          domain={[0, 5]}
          ticks={[0, 1, 2, 3, 4, 5]}
          tickLine={false}
          strokeWidth={0}
        />
        <Tooltip
          cursor={{ fill: "transparent" }}
          content={<TooltipContent />}
        />
        <CartesianGrid
          vertical={false}
          opacity={0.5}
          stroke="#E5E5EF"
          strokeDasharray={"3 3"}
          strokeLinecap="round"
        />
        <Bar
          dataKey="product_reviews_score"
          stackId="a"
          radius={[4, 4, 0, 0]}
          onMouseEnter={(_, data) => setActiveIndex(data)}
          onMouseLeave={() => setActiveIndex(null)}
        >
          {data[year].map((entry, index) => (
            <Cell
              cursor="pointer"
              fill={index === activeIndex ? "#0069E5" : "#F0E5FC"}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
export default ReviewBarChart;
