import NoDrafts from '../NoDrafts/index.module.css';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import error404Animation from './error404Animation.json';
import React from 'react';

const NotFoundPage = () => {
	return (
			<div className='w-screen h-screen flex items-center justify-center'>
				<div className={NoDrafts.intel_rounds_container + ' w-full'}>
					<div className={NoDrafts.drafts_container}>
						<div className={NoDrafts.drafts_icon_wrapper}>
							<Lottie
								style={{
									height: '150px',
									width: '150px',
								}}
								animationData={error404Animation}
								loop={true}
							/>
						</div>
						<div className={NoDrafts.drafts_message_wrapper}>
							<div className={NoDrafts.no_drafts_message}>
								We apologize, but this section is currently not available.
							</div>
						</div>
					</div>
					<div className={NoDrafts.active_rounds_button_wrapper}>
						<div className={NoDrafts.active_rounds_button}>
							<Link to='/' className={NoDrafts.button_text}>
								Return Back
							</Link>
						</div>
					</div>
				</div>
			</div>
	);
};

export default NotFoundPage;
