import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import RightArrow from "../../assets/icons/SidePanel/arrow-left.svg";
import Subscriptions from "../../assets/icons/SidePanel/subscription-icon.svg";
import EmailIcon from "../../assets/icons/SidePanel/email-icon.svg";
import AccountIcon from "../../assets/icons/SidePanel/account-setting-icon.svg";
import { ArrowRight01Icon } from "@hugeicons/react";

const Settings = () => {
  const currentPage =
    useLocation()?.pathname?.split("/").length > 2
      ? useLocation()?.pathname?.split("/")[2]
      : "account";

  const data = [
    {
      name: "Account",
      title: "account",
      icon: AccountIcon,
    },
    {
      name: "Subscription & Billing",
      title: "subscription",
      icon: Subscriptions,
    },
    {
      name: "Reports & Email",
      title: "email",
      icon: EmailIcon,
    },
    // {
    //   name: "Integration",
    //   title: "integration",
    //   icon: EmailIcon,
    // },
  ];

  return (
    <div className={styles.container}>
      <div
        className={" w-full flex flex-col xl:flex-row items-start gap-10 mt-5"}
      >
        <div className={styles.settingsFrame + " w-full xl:w-1/6 xl:min-w-64"}>
          {data.map((e) => (
            <Link
              className="w-full"
              key={e?.title}
              to={e?.title == "account" ? "/settings" : e?.title}
            >
              <div
                className={
                  e?.title === currentPage
                    ? styles.mail01Parent
                    : styles.accountSetting01Parent
                }
              >
                <img
                  className={styles.accountSetting01Icon}
                  alt={e?.name}
                  src={e?.icon}
                  width={30}
                  height={30}
                />
                <div
                  className={
                    e?.title === currentPage
                      ? styles.emailNotifications
                      : styles.account
                  }
                >
                  {e?.name}
                </div>
                {e?.title === currentPage && (
                  <ArrowRight01Icon
                    size={18}
                    color="#3D87DD"
                    variant="stroke"
                    type="rounded"
                  />
                )}
              </div>
            </Link>
          ))}
        </div>
        <div className={styles.settingsContainer + " w-full"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Settings;
