import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

// Sample data for USA, Canada, UK, and Australia percentages

const COLORS = ['#4A3AFF', '#2D5BFF', '#93AAFD', '#C6D2FD', '#E5EAFC']; // Colors for each country

const CountryPieChart = ({ data }) => {
	return (
		<div className='flex flex-col w-full h-auto px-4 pt-2'>
			<div className='text-white'>
				<span className='font-semibold text-xs'>Visits by Country</span>
			</div>
			<div className='h-[calc(100%-50px)] w-full flex flex-row justify-between gap-1'>
				<div className='h-full w-1/2'>
					<ResponsiveContainer width='100%' height='100%'>
						<PieChart width={55} height={55}>
							<Pie
								data={data}
								cx='50%' // Center X for full circle
								cy='50%' // Center Y for full circle
								outerRadius={27.5}
								paddingAngle={0} // Remove padding between slices
								labelLine={false} // Hide lines connecting labels
								// Custom label display
								dataKey='percentage' // Key to access pie chart data values
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORS[index % COLORS.length]} // Color for each slice
										stroke='none' // Remove border from slices
									/>
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className='h-auto relative w-1/2 py-5 grow space-y-1'>
					{data.map((item, index) => (
						<div className='flex flex-row flex-wrap items-center justify-between'>
							<div className='flex flex-row items-center justify-start gap-2'>
								<div
									className='h-2 w-2 rounded-sm'
									style={{ backgroundColor: COLORS[index] }}
								/>
								<p className='text-white text-[10px]'>{item.country}</p>
							</div>
							<p className='text-[#615E83] text-[10px]'>{item.percentage}%</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CountryPieChart;
