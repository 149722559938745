import { GitPullRequestDraftIcon } from "@hugeicons/react";
import React from "react";

const NoData = () => {
  return (
    <div className="w-full h-full flex flex-col gap-6 items-center justify-center">
      <GitPullRequestDraftIcon
        size={80}
        color={"#abd1ff"}
        variant={"duotone"}
      />
      <h6 className="text-[--dark-text] text-xs font-semibold text-center block relative w-full">
        This section currently has no available intelligence
      </h6>
    </div>
  );
};

export default NoData;
