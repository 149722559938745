import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	Navigate,
} from 'react-router-dom';
import Landing from '../../layout/Landing';
import Rounds from '../../Pages/Rounds';
import Settings from '../../Pages/Settings';
import SingleRound from '../../Pages/SingleRound';
import Foundations from '../../Pages/Foundations';
import AnalysisPage from '../../Pages/AnalysisPage';
import NoDraftsBlocker from '../../Components/Blockers/NoDrafts/Index';
import MobileBlocker from '../../Components/Blockers/MobileBlocker';
import SessionTimeout from '../../assets/icons/blockers/SessionTimeout';
import CrashedScreen from '../../Components/Blockers/CrashedScreen';
import NotFoundPage from '../../Components/Blockers/404NotFound';
import TargetCompany from '../../Pages/TargetCompany';
import OverviewTab from '../../Components/Target/OverviewTab';
import TechStackTab from '../../Components/Target/TechStackTab';
import PricingTab from '../../Components/Target/PricingTab';
import TrafficTab from '../../Components/Target/TrafficTab';
import ReviewTab from '../../Components/Target/ReviewTab';
import NewsTab from '../../Components/Target/NewsTab';
import FundingTab from '../../Components/Target/FundingTab';
import AcquisitionsTab from '../../Components/Target/AcquisitionsTab';
import WorkforceTab from '../../Components/Target/WorkforceTab';
import Targets from '../../Pages/Targets';
import AccountPage from '../../Pages/Settings/SidePage/AccountPage';
import EmailPage from '../../Pages/Settings/SidePage/EmailPage';
import SubscriptionBasicPlanPage from '../../Pages/Settings/SidePage/SubscriptionPage';
import IntegrationPage from '../../Pages/Settings/SidePage/IntegrationPage';
import TargetSearch from '../../Pages/Targets/TargetSearch';
import PDFPages from '../../Pages/PDFPages';

export const PrivateRoutes = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='/' element={<Landing />}>
				{/* Target routes */}
				<Route index element={<Targets />} />
				<Route path='targets/search-company' element={<TargetSearch />} />
				<Route path='target/:companyId' element={<TargetCompany />}>
					<Route index element={<OverviewTab />} />
					<Route path='tech-stack' element={<TechStackTab />} />
					<Route path='pricing' element={<PricingTab />} />
					<Route path='traffic' element={<TrafficTab />} />
					<Route path='review' element={<ReviewTab />} />
					<Route path='news' element={<NewsTab />} />
					<Route path='funding' element={<FundingTab />} />
					<Route path='acquisitions' element={<AcquisitionsTab />} />
					<Route path='workforce' element={<WorkforceTab />} />
				</Route>
				{/* Product Comparisons - Rounds and Foundation */}
				<Route path='rounds' element={<Rounds />} />
				<Route path='round/:roundname' element={<SingleRound />} />
				<Route path='foundations' element={<Foundations />} />
				{/* Settings */}
				<Route path='settings' element={<Settings />}>
					<Route index  element={<AccountPage />} />
					<Route path='email' element={<EmailPage />} />
					<Route path='subscription' element={<SubscriptionBasicPlanPage />} />
					<Route path='integration' element={<IntegrationPage />} />
				</Route>
				{/* Blockers */}
				<Route path='no-drafts' element={<NoDraftsBlocker />} />
				<Route path='mobile-blocker' element={<MobileBlocker />} />
				<Route path='crashed-screen' element={<CrashedScreen />} />
			</Route>
			<Route path='pdf/:companyId' element={<PDFPages />} />
			<Route path='analysis' element={<AnalysisPage />} />
			{/* 404 route handler */}
			<Route path='*' element={<NotFoundPage />} />
		</>
	)
);
