import { InformationSquareIcon, MoneySendSquareIcon } from "@hugeicons/react";
import React from "react";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";
import SectionCard from "../OverviewTab/SectionCard";
import { useOutletContext } from "react-router-dom";
import NoData from "../NoData";

const PricingTab = () => {
  const { pricing } = useOutletContext();
  const { colorMode } = useTheme();
  const pricingData = [
    {
      label: "Free Trial",
      data: pricing?.additional?.free_trial,
    },
    {
      label: "Product Demo",
      data: pricing?.additional?.product_demo,
    },
    {
      label: "Downloadable",
      data: pricing?.additional?.downloadable,
    },
    {
      label: "Mobile Apps",
      data: pricing?.additional?.mobile_apps,
    },
  ];
  return pricing?.data ? (
    <div className="w-full h-full flex justify-between">
      {/* Left column */}
      {/* Show only if data is present */}
      <div className="w-3/5 h-full flex flex-col gap-5">
        {/* Public Pricing */}
        <div className="w-auto relative px-2.5 py-2 rounded inline-flex gap-1.5 items-center text-[10px] text-[--secondary-text] border border-[--secondary-border] leading-[18px]">
          <MoneySendSquareIcon
            color={colorMode === "dark" ? "#e1e1e1" : "#1e1e1e"}
            size={18}
            variant="stroke"
            type="rounded"
          />
          Public Pricing: {pricing?.public_pricing}
        </div>
        {pricing?.pricing?.length > 0 ? (
          <div className="w-full flex-grow rounded border border-[--tab-section-border] flex flex-col gap-2.5 py-5 pl-6 pr-10 overflow-y-scroll ">
            <div className="w-full flex justify-start items-center text-[10px] text-[--dark-text]">
              {/* 1st col width */}
              <div className="w-full px-5">Type</div>
              <div className="w-32 px-5">Pricing</div>
              <div className="w-full px-5"></div>
            </div>
            {pricing?.pricing?.map((item, index) => (
              <div className="w-full flex justify-start items-center text-xs leading-6 text-[--dark-text] rounded border border-[--tab-section-border] py-[7px] divide-x divide-[--tab-section-border]">
                <div className="w-full px-5">{item.type}</div>
                <div className="w-32 px-5 text-sm leading-6">
                  ${item.pricing}
                </div>
                <div className="w-full px-5"> {item.repeat}</div>
              </div>
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </div>
      {/* Show only if data is present */}
      {pricing?.additional?.data && (
        <div className="w-1/3 h-full flex flex-col gap-3">
          <div className="w-full text-xs text-[--dark-text] flex gap-1.5">
            <InformationSquareIcon
              size={14}
              color="#5092E0"
              variant="stroke"
              type="rounded"
            />
            Additional Intelligence
          </div>
          {pricingData.map((info) => (
            <SectionCard key={info.label} label={info.label} data={info.data} />
          ))}
        </div>
      )}
    </div>
  ) : (
    <NoData />
  );
};

export default PricingTab;
