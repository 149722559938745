import "./index.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ToggleThemeBtn from "./ToggleButton";
import { useUser } from "@clerk/clerk-react";
import ThreeDotMenu from "../../assets/icons/threeDotMenu.svg";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
import { useNavigate } from "react-router-dom";

const SidePanelHeader = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { colorMode, toggleColorMode } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenuBorder = {
    border: `2px solid ${colorMode === "dark" ? "#666" : "#fff"}`,
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccountClick = () => {
    navigate("/settings");
    handleMenuClose();
  };

  return (
    <div className={`side-user-panel-contents side-head-bg h-[60px]`}>
      <div className="user-profile">
        <img className="user-pic" src={user?.imageUrl} alt="User Profile" />
        <div className="user-profile-name">
          Hi, {user?.firstName || user?.emailAddresses?.[0]?.emailAddress}
        </div>
      </div>
      {/* <IconButton onClick={handleMenuOpen}>
        <img src={ThreeDotMenu} alt="Menu" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={toggleMenuBorder}
      >
        <MenuItem
          className={colorMode == "dark" && "hover:!bg-[--active-sidebar-bg]"}
          onClick={handleAccountClick}
        >
          Go to Account
        </MenuItem>
        <div className="flex justify-between items-center py-1.5 px-4 gap-4">
          Theme:
          <ToggleThemeBtn />
        </div>
      </Menu> */}
    </div>
  );
};

export default SidePanelHeader;
