import { Activity01Icon, PauseIcon } from "@hugeicons/react";
import React from "react";

const inactiveIcon = (
  <PauseIcon size={16} color="#3d86dc" variant="stroke" type="rounded" />
);
const activeIcon = (
  <Activity01Icon
    size={16}
    color={"#3d86dc"}
    variant={"stroke"}
    type="rounded"
  />
);

const StatusTag = ({ status }) => {
  return (
    <span className="h-6 w-auto px-2.5 bg-[#3d86dc]/10 rounded-3xl justify-center items-center gap-1 inline-flex">
      {status === "active" ? activeIcon : inactiveIcon}
      <span className=" text-sm font-medium capitalize">{status}</span>
    </span>
  );
};

export default StatusTag;
