import "./index.css";
import HelpIcon from "../../assets/icons/help.svg";
import StartIcon from "../../assets/icons/start.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import { useContext } from "react";
import { useClerk } from "@clerk/clerk-react";
import { AuthContext } from "../../Context/AuthContext";
import { setToken } from "../../Context/AuthContext/action";
import { clearStorage } from "../../utils/Storage";
import PlanUsage from "../SidebarSubscriptions/PlanUsage";
import SidePanelHeader from "./SidePanelHeader";
import SidebarList from "./SidebarList";

const SidePanel = () => {
  const { signOut } = useClerk();
  const { dispatch } = useContext(AuthContext);

  const handleSignOut = async () => {
    clearStorage();
    dispatch({ type: setToken, payload: "" });
    await signOut();
  };

  return (
    <div className="main-side-panel-contents">
      {/* Empty div required for flex */}
      <div>
        <SidePanelHeader />
        <SidebarList />
      </div>
      <div>
        <PlanUsage />
      </div>
      <div className="side-footer-items">
        <div
          className="side-option"
          style={{ fontSize: "14px" }}
          onClick={() =>
            window.open(
              "https://help.sharbo.io/en/articles/10252402-sharbo-demo",
              "_blank"
            )
          }
        >
          <img src={StartIcon} alt="Start Icon" />
          {"Quick Start"}
        </div>
        <div
          className="side-option"
          style={{ fontSize: "14px" }}
          onClick={() => window.open("https://help.sharbo.io/", "_blank")}
        >
          <img src={HelpIcon} alt="Help Icon" />
          {"Help Center"}
        </div>
        <div onClick={handleSignOut} className="side-option">
          <img src={LogoutIcon} alt="Logout Icon" />
          {"Logout"}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
