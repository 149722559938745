import React from "react";

const AgeProgressBar = ({ ageData, totalVisits = false }) => {
  function convertAgeData(input) {
    return Object.entries(input).map(([key, value]) => {
      const age = key
        .replace("age_", "") // Remove 'age_' prefix
        .replace("_percentage", "") // Remove '_percentage' suffix
        .replace("_plus", "+") // Replace '_plus' with '+'
        .replace("_", "-"); // Replace '_' with '-'

      return {
        age,
        percentage: value,
      };
    });
  }
  const data = convertAgeData(ageData);

  return (
    <div className="text-white rounded-md w-full max-w-md">
      {/* Header */}
      <div className="flex justify-between items-center mb-3">
        <span className="font-semibold text-xs">Visits by Age</span>
        {totalVisits && <span className="font-semibold text-xs">31,863</span>}
      </div>
      <hr />
      {/* Progress Bars */}
      <div className=" text-[#615E83] space-y-2 mt-3">
        {data.map(({ age, percentage }, index) => (
          <div key={index} className="flex items-center space-x-1">
            {/* Age Label */}
            <span className="text-[10px] w-11 text-nowrap">{age}</span>

            {/* Progress Bar */}
            <div className="relative w-full bg-white rounded-full h-2">
              {/* Filled bar */}
              <div
                className="bg-blue-500 h-2 rounded-full"
                style={{ width: `${percentage}%` }}
              ></div>
              {/* Tooltip */}
            </div>

            {/* Percentage */}
            <span className="text-xs">{percentage.toFixed(1)}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgeProgressBar;
