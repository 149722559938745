import TrackHeader from "../../Components/TrackHeader";
import TrackCard from "../../Components/TrackCard";
import { useState } from "react";

const TargetList = ({ companies, setSearchCompany, sortBy, setSortBy }) => {
  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  const filterCompany = (searchTerm) => {
    if (searchTerm === "") {
      setFilteredCompanies(companies);
      return;
    }
    const filtered = companies.filter((company) =>
      company?.company_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  return (
    <div className="h-full w-full flex flex-col justify-start items-center p-4 gap-5">
      <div className="w-full text-white">
        Saved Targets ({companies?.length})
      </div>
      <TrackHeader
        setSearchCompany={setSearchCompany}
        filterCompany={filterCompany}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
      {filteredCompanies?.length > 0 ? (
        <div className="grid grid-cols-2 w-full justify-center items-center gap-6 px-5">
          {filteredCompanies.map((item) => (
            <TrackCard companyData={item} />
          ))}
        </div>
      ) : (
        <div className="h-full w-full flex flex-col justify-center items-center p-4 gap-5">
          <h1 className="text-[--dark-text] text-base text-center font-medium">
            No Saved target found
          </h1>
        </div>
      )}
    </div>
  );
};

export default TargetList;
