import React from "react";

const ArticleCard = ({ headline, date, news, url }) => {
  return (
    <div className="w-full rounded border border-[--tab-section-border] text-[--dark-text]">
      {/* Header section */}
      <div className="w-full h-auto px-4 py-2.5 flex items-center justify-between gap-5 border-b border-[--tab-section-border] rounded-t bg-[--active-card-title-bg]">
        <span className=" text-xs font-medium">{headline ?? "Headline"}</span>
        <span className="h-auto px-4 rounded-2xl bg-text-light/10 justify-center items-center gap-0.5 text-nowrap inline-flex text-[10px] leading-8 font-medium tracking-tight">
          Published on: {date ?? "No intelligence available"}
        </span>
      </div>
      {/* Card body */}
      <div className="w-full h-auto  min-h-12 px-4 py-5 text-[--secondary-text] text-[10px] font-medium flex gap-5 items-center justify-between">
        <div className="w-auto max-w-md min-h-16">
          {news ?? "No intelligence available"}
        </div>
        <button
          onClick={() => window.open(url, "_blank")}
          className="w-auto relative text-nowrap self-end text-xs px-2.5 rounded leading-8 flex items-center justify-between gap-2.5 border text-[--primary-blue] 
                    border-[--primary-blue] bg-[#627b99]/20 font-normal"
        >
          Go to Article
        </button>
      </div>
    </div>
  );
};

export default ArticleCard;
