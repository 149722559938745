import {
	Download05Icon,
	KeyframesDoubleAddIcon,
	RemoveCircleIcon,
	StarsIcon,
} from '@hugeicons/react';
import StatusTag from '../../Common/Card/StatusTag';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import {
	removeSavedTarget,
	saveCompanyAsTarget,
	updateAlertStatus,
	validateCoreSignalLimit,
} from '../../../services';
import { CircularProgress, Modal } from '@mui/material';
import { useContext, useState } from 'react';
import UsageLimitModal from '../../Common/UsageLimitModal';
import { SubscriptionContext } from '../../../Context/SubscriptionContext';
import PDFPages from '../../../Pages/PDFPages';

const HiddenPDFContent = ({ data }) => (
	<div className='h-screem w-screen'>
		<PDFPages />
	</div>
);

const TargetHeader = ({ data, setData }) => {
	const { fetchSubscriptionData } = useContext(SubscriptionContext);
	const companyId = data?.company_id;

	const pdfRef = useRef();
	const portalRoot = document.createElement('div');
	portalRoot.style.display = 'none';
	document.body.appendChild(portalRoot);

	const [openUsageModal, setOpenUsageModal] = useState(false);
	const [alertLoader, setAlertLoader] = useState(false);
	const [savingLoader, setSavingLoader] = useState(false);
	const [alertData, setAlertData] = useState(data?.alert_status);
	const [savingData, setSavingData] = useState(data?.saved);

	const handleSaveTarget = async () => {
		try {
			setSavingLoader(true);
			const res = await validateCoreSignalLimit('save_company_target');
			if (!res) {
				setOpenUsageModal(true);
				setSavingLoader(false);
				return;
			}
			const response = await saveCompanyAsTarget(companyId);
			if (!response?.details) setSavingData(true);
			setSavingLoader(false);
			fetchSubscriptionData();
		} catch (e) {
			console.error(e);
			setSavingLoader(false);
		}
	};

	const handleRemoveTarget = async () => {
		try {
			setSavingLoader(true);
			const response = await removeSavedTarget(companyId);
			if (!response?.details) setSavingData(false);
			setSavingLoader(false);
			fetchSubscriptionData();
		} catch (e) {
			console.error(e);
			setSavingLoader(false);
		}
	};

	const changeAlertStatus = async () => {
		try {
			setAlertLoader(true);
			const response = await updateAlertStatus(companyId, !data?.alert_status);
			if (!response?.details) {
				setData(response);
				setAlertData(response?.alert_status);
			}
			setAlertLoader(false);
		} catch (e) {
			console.error(e);
			setAlertLoader(false);
		}
	};

	return (
		<div className='w-full h-auto max-h-32 flex gap-5 justify-between text-[--dark-text] '>
			<Modal
				styles={'width:300px'}
				open={openUsageModal}
				onClose={() => setOpenUsageModal(false)}>
				<UsageLimitModal closeModal={() => setOpenUsageModal(false)} />
			</Modal>
			{/* Left column */}
			<div className='flex flex-col gap-5 justify-between'>
				{/* 1st line */}
				<div className='w-full h-8 flex gap-5 items-center'>
					{data?.companyLogo && (
						<img
							src={data?.companyLogo}
							alt='Company Logo'
							className='w-8 h-8 rounded-lg object-contain'
						/>
					)}
					<h1 className='text-lg font-medium tracking-tight'>
						{data?.company_name}
					</h1>
				</div>
				{/* 2nd line */}
				<div className='w-auto max-w-max h-8 px-2.5 py-1 rounded border border-[--secondary-border] inline-flex items-center gap-3 text-xs font-medium '>
					<StarsIcon
						size={16}
						color='#5092E0'
						variant='stroke'
						type='rounded'
					/>
					<span className='text-xs font-light'>Industry:</span>
					<span className='text-xs font-medium'> {data?.industry}</span>
				</div>
				{/* 3rd line */}
				<div className='w-full flex h-6 items-center gap-2.5 text-xs  tracking-tight leading-6'>
					<span className='font-normal'>Data Freshness:</span>
					<span className='font-medium'>{data.data_freshness}</span>
				</div>
			</div>
			<div className='flex flex-col gap-5 justify-between items-end'>
				<div className='relative w-auto'>
					{savingData ? (
						<button
							onClick={handleRemoveTarget}
							className='w-auto h-8 rounded px-2.5 bg-[--blocker-analysis-heading] text-white text-xs font-medium leading-8 flex items-center justify-center gap-1'>
							{savingLoader ? (
								<CircularProgress
									size={16}
									sx={{
										color: 'white',
									}}
								/>
							) : (
								<RemoveCircleIcon
									size={16}
									color='#ffffff'
									variant='stroke'
									type='rounded'
								/>
							)}
							Remove from Targets
						</button>
					) : (
						<button
							onClick={handleSaveTarget}
							className='w-auto h-8 rounded px-2.5 bg-[--primary-blue] text-white text-xs font-medium leading-8 flex items-center justify-center gap-1'>
							{savingLoader ? (
								<CircularProgress
									size={16}
									sx={{
										color: 'white',
									}}
								/>
							) : (
								<KeyframesDoubleAddIcon
									size={16}
									color='#ffffff'
									variant='stroke'
									type='rounded'
								/>
							)}
							Save as Target
						</button>
					)}
				</div>
				{savingData && (
					<>
						<div className='w-full flex h-6 items-center gap-2.5'>
							<span className='text-xs font-normal tracking-tight leading-6'>
								Alert Status:
							</span>
							{alertLoader ? (
								<CircularProgress
									size={16}
									sx={{
										color: 'var(--primary-blue)',
									}}
								/>
							) : (
								<button onClick={changeAlertStatus}>
									<StatusTag status={alertData ? 'active' : 'inactive'} />
								</button>
							)}
						</div>
						{/* <div className='relative w-auto'>
							<button
								onClick={() =>
									window.open(
										`${window.location.protocol}//${window.location.hostname}:${window.location.port}/pdf/${data.company_id}`
									)
								}
								className='w-auto h-8 rounded px-2.5 border border-[--secondary-border] text-xs font-medium leading-8 flex items-center justify-center gap-1 disabled:opacity-45 disabled:pointer-events-none'>
								<Download05Icon
									size={16}
									color='#5092E0'
									variant='stroke'
									type='rounded'
								/>
								Export
							</button>
						</div> */}
					</>
				)}
			</div>
		</div>
	);
};

export default TargetHeader;
