import React from 'react';
import Cover1 from '../../assets/images/cover-image-1.svg';
import Cover2 from '../../assets/images/cover-image-2.svg';
import Cover3 from '../../assets/images/cover-image-3.svg';
import SharboLogo from '../../assets/images/sharbo-logo.svg';

const CoverPage = ({}) => {
	return (
		<div className='flex flex-col w-full h-[1056px]  justify-end items-start bg-black relative'>
			<img src={Cover1} className='absolute -top-10 right-0' />
			<img src={Cover2} className='absolute top-10 left-0' />
			<img src={Cover3} className='absolute top-[400px] right-0' />
			<div className='flex w-full flex-col gap-4 justify-end items-start px-20 pb-32'>
				<img src={SharboLogo} className='w-80 -ml-10' />
				<div className='h-2 w-full bg-white -mt-32' />
				<h3 className='text-blue-500 text-[70px]'>INTELLIGENCE</h3>
				<h3 className='text-white text-[36px]'>REPORT</h3>
			</div>
		</div>
	);
};

export default CoverPage;
