import React, { useState, useEffect, useCallback, useContext } from "react";
import styles from "./index.module.css";
import { Button, CircularProgress } from "@mui/material";
import ArrowReg from "../../../../assets/icons/regenerate.svg";
import {
  deleteCustomAnalysis,
  generateCustomAnalysis,
  getAnalysisDetail,
  updateAnalysisContent,
} from "../../../../services";
import { ColorPattern } from "../../../../constant/Color";
import { useTheme } from "../../../../Context/ThemContext/ThemeContext";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import RichTextEditor from "../../TextEditor";
import AnalysisLoader from "../AnalysisLoader";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import { SET_API_LOADING } from "../../../../Context/OutpointsContext/action";
import { PencilEdit02Icon } from "@hugeicons/react";
import EditAnalysisModal from "../EditAnalysisModal";

const TabSectionTemplate = ({
  custom,
  heading,
  setTabData,
  setActiveTab,
  type,
  activeTab,
  section_id,
  analysisData,
  handleRegenerate,
  setAnalysisData,
  tabData,
  existingRound = false,
  setShowLoaderText,
  previewEnabled = false,
}) => {
  const { colorMode } = useTheme();
  const { state, dispatch } = useContext(OutpointContext);
  const [input, setInput] = useState("");
  const [textareaContent, setTextareaContent] = useState("");
  const [sectionLoading, setSectionLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [addCustomInput, setAddCustomInput] = useState(false);

  // console.log("section_id", section_id);

  let customSection = (
    existingRound ? state?.singleRound?.analysis : analysisData
  )?.custom_section.custom_section?.find?.(
    (e) => e.customsectiontitle == activeTab
  );
  const generateCustomAnalysisFunction = async (input, regenerate = false) => {
    setAddLoading(true);
    let result;
    try {
      result = await generateCustomAnalysis(
        analysisData.analysis.analysis_id,
        input
      );
      if (result.data) {
        if (regenerate) {
          // Change/Regenerate existing custom section
          setTextareaContent(result.data.customsectiondata);
          setAnalysisData({
            ...analysisData,
            custom_section: {
              ...analysisData.custom_section,
              custom_section: analysisData.custom_section.custom_section.map(
                (e) => (e.customsectiontitle === input ? result.data : e)
              ),
            },
          });
        } else {
          // Add new custom section
          setAnalysisData({
            ...analysisData,
            custom_section: {
              ...analysisData.custom_section,
              custom_section: [
                ...analysisData.custom_section.custom_section,
                result.data,
              ],
            },
          });
        }
      }
    } catch (error) {
      console.error("Error generating analysis:", error);
    }
    setAddLoading(false);
    return result;
  };
  const deleteCustom = async () => {
    setDelLoading(true);

    try {
      const result = await deleteCustomAnalysis(customSection.customsectionid);
      if (result.deleted_custom_section_id) {
        setActiveTab("Custom Section");
        setAnalysisData({
          ...analysisData,
          custom_section: {
            ...analysisData.custom_section,
            custom_section: analysisData.custom_section.custom_section.filter(
              (e) => e.customsectiontitle !== activeTab
            ),
          },
        });

        setTabData((prev) => prev.filter((item) => item.title !== activeTab));
      }
    } catch (error) {
      console.error("Error generating analysis:", error);
    }
    setDelLoading(false);
  };
  const resetContent = () => {
    if (analysisData && section_id) {
      let content = "";
      // console.log("Section ID", section_id);
      switch (section_id) {
        case "benefits_of_using_foundation":
          content =
            (existingRound
              ? state?.singleRound?.analysis?.analysis?.benefitsfoundation
              : analysisData?.analysis["benefitsfoundation"]) ??
            "Not applicable";
          break;
        case "limitations_of_using_competitor":
          content =
            (existingRound
              ? state?.singleRound?.analysis?.analysis?.limitationscompetitor
              : analysisData?.analysis["limitationscompetitor"]) ??
            "Not applicable";
          break;
        case "key_distinction":
          // console.log("key_distinction", analysisData);
          content =
            (existingRound
              ? state?.singleRound?.analysis?.analysis?.key_distinction
              : analysisData?.analysis["key_distinction"]) ?? "Not applicable";
          break;
        case "pricing":
          content =
            (existingRound
              ? state?.singleRound?.analysis?.analysis?.pricing
              : analysisData?.analysis["pricing"]) ?? "Not applicable";
          break;
        case "customgen":
          content = customSection?.customsectiondata;
          break;
        default:
          content = "Not applicable";
      }
      setTextareaContent(content);
      setEditContent(false);
    }
  };

  useEffect(() => {
    console.log("Calling reset content");
    resetContent();
    setEditContent(false);
  }, [section_id, sectionLoading, previewEnabled]);

  const handleAddAnalyze = async () => {
    dispatch({ type: SET_API_LOADING, payload: "INPROGRESS" });
    if (!input.trim()) {
      toast.error("Please enter a topic before adding a custom section.");
      return;
    }

    // Convert input to lowercase for case-insensitive matching
    const normalizedInput = input.trim().toLowerCase();
    const existingTab = tabData.find(
      (item) => item?.title?.trim().toLowerCase() === normalizedInput
    );
    if (existingTab) {
      toast.error("Custom section with this name already exists.");
      dispatch({ type: SET_API_LOADING, payload: false });
      return;
    }

    try {
      let result = await generateCustomAnalysisFunction(input.trim());
      const res = await getAnalysisDetail(analysisData.analysis.analysis_id);
      if (result?.data) {
        setTabData((prev) => {
          let lastElement = prev[prev.length - 1];
          let newarr = [...prev];
          newarr.pop();
          newarr.push(
            {
              id: result?.data?.customsectionid,
              title: input.trim(),
              type: "custom",
            },
            lastElement
          );

          return newarr;
        });
        setActiveTab(input.trim());
        setAnalysisData(res?.data);
        // setAnalysisData({
        //   ...analysisData,
        //   custom_section: {
        //     ...analysisData.custom_section,
        //     custom_section: [
        //       ...analysisData.custom_section.custom_section,
        //       result.data,
        //     ],
        //   },
        // });
        dispatch({ type: SET_API_LOADING, payload: false });
        toast.success("Created custom section successfully");
      } else {
        toast.error(result?.detail || result?.detail?.[0]?.msg);
        dispatch({ type: SET_API_LOADING, payload: false });
      }
    } catch (error) {
      console.error("Error in generateCustomAnalysis:", error);
      dispatch({ type: SET_API_LOADING, payload: false });
      toast.error("Failed to create custom section. Please try again.");
    }
  };

  const handleContentUpdate = async () => {
    try {
      setEditContent(false);
      setShowLoaderText(false);
      setSectionLoading(true);
      let url = "/analysis/save/",
        body = {};
      let newData = analysisData?.analysis;

      switch (section_id) {
        case "benefits_of_using_foundation":
          url = url + "benefits";
          body = { analysis_content: textareaContent };
          if (existingRound)
            dispatch({
              type: "SET_SINGLE_ROUND",
              payload: {
                ...state.singleRound,
                analysis: {
                  ...analysisData,
                  analysis: { ...newData, benefitsfoundation: textareaContent },
                },
              },
            });
          break;
        case "limitations_of_using_competitor":
          url = url + "limitations";
          body = { analysis_content: textareaContent };
          if (existingRound)
            dispatch({
              type: "SET_SINGLE_ROUND",
              payload: {
                ...state.singleRound,
                analysis: {
                  ...analysisData,
                  analysis: {
                    ...newData,
                    limitationscompetitor: textareaContent,
                  },
                },
              },
            });
          break;
        case "key_distinction":
          url = url + "key-distinctions";
          body = { analysis_content: textareaContent };
          if (existingRound)
            dispatch({
              type: "SET_SINGLE_ROUND",
              payload: {
                ...state.singleRound,
                analysis: {
                  ...analysisData,
                  analysis: { ...newData, key_distinction: textareaContent },
                },
              },
            });
          break;
        default:
          url = url + "custom-analysis";
          body = {
            custom_section_title: customSection.customsectiontitle,
            custom_section_data: textareaContent,
          };
          setAnalysisData({
            ...analysisData,
            custom_section: {
              ...analysisData.custom_section,
              custom_section: analysisData.custom_section.custom_section.map(
                (e) =>
                  e.customsectiontitle === customSection.customsectiontitle
                    ? { ...e, customsectiondata: textareaContent }
                    : e
              ),
            },
          });
      }
      url = url + "/" + analysisData.analysis.analysis_id;
      const res = await updateAnalysisContent(url, body);
      if (res?.data) {
        handleStateUpdate();
        if (!existingRound) {
          setAnalysisData({ ...analysisData, analysis: res?.data });
        }
        toast.success("Your edits are saved successfully");
        setSectionLoading(false);
      } else {
        setSectionLoading(false);
        throw new Error("Error in updating content");
      }
      setShowLoaderText(true);
    } catch (error) {
      console.error(error);
      setSectionLoading(false);
      resetContent();
      toast.error("Failed to save your edits. Please try again.");
    }
  };

  function getcurrentTabName() {
    switch (activeTab) {
      case `Benefits of using (${
        (existingRound
          ? state.singleRound.foundation?.foundation_name
          : state.roundsForm.foundation?.foundation_name) || "Foundation Name"
      })`: {
        return "Benefits";
      }

      case `Limitations of using (${
        (existingRound
          ? state.singleRound.competitor?.competitor_name
          : state.roundsForm.competitor?.competitor_name) || "Competitor Name"
      })`: {
        return "Limitations";
      }
      case "Key Distinctions": {
        return "Key";
      }
      default:
        return "Custom";
    }
  }

  const handleStateUpdate = () => {
    let newData = analysisData?.analysis;
    switch (section_id) {
      case "benefits_of_using_foundation":
        if (existingRound)
          dispatch({
            type: "SET_SINGLE_ROUND",
            payload: {
              ...state.singleRound,
              analysis: {
                ...analysisData,
                analysis: { ...newData, benefitsfoundation: textareaContent },
              },
            },
          });
        break;
      case "limitations_of_using_competitor":
        if (existingRound)
          dispatch({
            type: "SET_SINGLE_ROUND",
            payload: {
              ...state.singleRound,
              analysis: {
                ...analysisData,
                analysis: {
                  ...newData,
                  limitationscompetitor: textareaContent,
                },
              },
            },
          });
        break;
      case "key_distinction":
        if (existingRound)
          dispatch({
            type: "SET_SINGLE_ROUND",
            payload: {
              ...state.singleRound,
              analysis: {
                ...analysisData,
                analysis: { ...newData, key_distinction: textareaContent },
              },
            },
          });
        break;
      default:
        setAnalysisData({
          ...analysisData,
          custom_section: {
            ...analysisData.custom_section,
            custom_section: analysisData.custom_section.custom_section.map(
              (e) =>
                e.customsectiontitle === customSection.customsectiontitle
                  ? { ...e, customsectiondata: textareaContent }
                  : e
            ),
          },
        });
    }
  };

  return sectionLoading ? (
    <div className="flex h-400 items-center justify-center">
      <AnalysisLoader />
    </div>
  ) : (
    <section
      id="tabsection_template"
      className={
        existingRound
          ? styles.tabSectionTemplate__wrapper +
            " overflow-y-scroll scrollbar-none"
          : styles.tabSectionTemplate__wrapper
      }
    >
      {heading && <p>{heading}</p>}
      {custom && section_id == "custom" && (
        <span>Sharbo Al will generate analysis for each section you add</span>
      )}
      {type && custom ? (
        <div className={styles.textareawrapper + " !max-h-full !h-full"}>
          <>
            <div className={styles.textareabtns}>
              <div className={styles.textareabtns__left}>{activeTab}</div>
              {!previewEnabled && (
                <div className={styles.textareabtns__right}>
                  <Button
                    id={"regenerate" + getcurrentTabName(activeTab) + "Btn"}
                    onClick={() => handleRegenerate("custom")}
                    variant="outlined"
                    className="hover:scale-105"
                    sx={{
                      height: "36px",
                      borderRadius: "7px",
                      border: "0.429px solid #3F628B",
                      borderWidth: "0.429px !important",
                      background: "transparent",
                      padding: "0px 10px",
                      color: ColorPattern[colorMode].borderBottom2,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                      textTransform: "none",
                    }}
                  >
                    <img
                      alt=""
                      src={ArrowReg}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    {addLoading && (
                      <CircularProgress
                        sx={{
                          color: "blue",
                          height: "20px !important",
                          width: "20px !important",
                          position: "absolute",
                          top: "5px",
                        }}
                      />
                    )}
                    Regenerate
                  </Button>
                  <button
                    onClick={() => setEditContent(true)}
                    className={`${styles.saveEditBtn} disabled:pointer-events-none disabled:cursor-default hover:scale-105 origin-bottom ml-2.5 h-9 text-sm rounded-lg`}
                  >
                    <PencilEdit02Icon
                      size={16}
                      color={"currentColor"}
                      variant={"stroke"}
                      type={"rounded"}
                    />
                    Edit Manually
                  </button>
                  <Button
                    disabled={delLoading}
                    onClick={deleteCustom}
                    variant="outlined"
                    className="hover:scale-105"
                    sx={{
                      height: "36px",
                      borderRadius: "7px",
                      borderWidth: "0.429px !important",
                      border: "0.429px solid #E55E5E",
                      background: "transparent",
                      padding: "0px 10px",
                      color: "#E55E5E",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                      textTransform: "none",
                      marginLeft: "10px",
                    }}
                  >
                    <svg
                      style={{ marginRight: "-3px" }}
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.22919 7.58333C1.22919 11.0466 4.03674 13.8542 7.50002 13.8542C10.9633 13.8542 13.7709 11.0466 13.7709 7.58333C13.7709 4.12005 10.9633 1.3125 7.50002 1.3125C4.03674 1.3125 1.22919 4.12005 1.22919 7.58333ZM10.4167 7.58333C10.4167 7.90551 10.1555 8.16667 9.83335 8.16667H5.16669C4.84452 8.16667 4.58335 7.90551 4.58335 7.58333C4.58335 7.26116 4.84452 7 5.16669 7H9.83335C10.1555 7 10.4167 7.26116 10.4167 7.58333Z"
                        fill="#E55E5E"
                      />
                    </svg>
                    {delLoading && (
                      <CircularProgress
                        sx={{
                          color: "blue",
                          height: "20px !important",
                          width: "20px !important",
                          position: "absolute",
                          top: "5px",
                        }}
                      />
                    )}
                    Remove
                  </Button>
                </div>
              )}
            </div>

            <div
              style={{
                padding: "10px",
                height: "calc(100% - 95px)",
              }}
              onDoubleClick={() => {
                if (!previewEnabled) setEditContent(true);
              }}
              className={` ${styles.scrollableContent} ${
                previewEnabled && "border-b-0"
              }
                ${
                  custom ? " !h-[calc(100%-64px)] border-b-0" : ""
                }  markdownDiv text-wrap break-keep
              `}
            >
              <ReactMarkdown
                className="break-keep"
                style={{
                  paddingBottom: "70px",
                }}
              >
                {textareaContent}
              </ReactMarkdown>
            </div>
          </>
        </div>
      ) : (
        <div
          style={{
            border: custom ? "none" : "",
            paddingLeft: custom ? "0" : "",
            paddingRight: custom ? "0" : "",
          }}
          className={styles.textareawrapper}
        >
          {(!custom && (
            <>
              <div
                onDoubleClick={() => {
                  if (!previewEnabled) setEditContent(true);
                }}
                className={` ${styles.scrollableContent} ${previewEnabled && "border-b-0"}
                 ${
                   existingRound ? " h-[240px]" : ""
                 }  markdownDiv text-wrap break-keep`}
              >
                <ReactMarkdown className="break-keep">
                  {textareaContent}
                </ReactMarkdown>
              </div>
              {!previewEnabled && (
                <div
                  className={
                    existingRound
                      ? "bg-[--light-bg] sticky bottom-0 left-2.5 z-10 w-full pt-2.5 flex"
                      : styles.stickyRegenerateButton + " flex"
                  }
                >
                  <Button
                    id={"regenerate" + getcurrentTabName(activeTab) + "Btn"}
                    onClick={() => handleRegenerate()}
                    variant="outlined"
                    className=""
                    sx={{
                      height: "36px",
                      borderRadius: "7px",
                      border: "0.429px solid #3F628B",
                      borderWidth: "0.429px !important",
                      background: "transparent",
                      padding: "0px 10px",
                      color: ColorPattern[colorMode].borderBottom2,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                      textTransform: "none",
                    }}
                  >
                    <img
                      alt=""
                      src={ArrowReg}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    Regenerate
                  </Button>
                  <button
                    onClick={() => setEditContent(true)}
                    className={`${styles.saveEditBtn} disabled:pointer-events-none disabled:cursor-default hover:scale-105 origin-bottom ml-2.5 h-9 text-sm rounded-lg`}
                  >
                    <PencilEdit02Icon
                      size={16}
                      color={"currentColor"}
                      variant={"stroke"}
                      type={"rounded"}
                    />
                    Edit Manually
                  </button>
                </div>
              )}
            </>
          )) ||
            (!previewEnabled && (
              <>
                {!addCustomInput && (
                  <Button
                    disabled={addLoading}
                    onClick={() => setAddCustomInput(true)}
                    variant="outlined"
                    className="hover:scale-105 hover:origin-top-left"
                    sx={{
                      height: "36px",
                      borderWidth: "0.429px !important",
                      borderRadius: "7px",
                      border: "0.429px solid #3F628B",
                      background: "transparent",
                      color: ColorPattern[colorMode].borderBottom2,
                      padding: "0px 10px",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                      textTransform: "none",
                    }}
                  >
                    <svg
                      style={{
                        marginRight: "0px",
                        width: "11px",
                        height: "11px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.65709 0.925781C4.65709 0.649639 4.43323 0.425781 4.15709 0.425781C3.88094 0.425781 3.65709 0.649639 3.65709 0.925781V4.0827H0.5C0.223858 4.0827 0 4.30656 0 4.5827C0 4.85884 0.223858 5.0827 0.5 5.0827H3.65709V8.24007C3.65709 8.51621 3.88094 8.74007 4.15709 8.74007C4.43323 8.74007 4.65709 8.51621 4.65709 8.24007V5.0827H7.81429C8.09043 5.0827 8.31429 4.85884 8.31429 4.5827C8.31429 4.30656 8.09043 4.0827 7.81429 4.0827H4.65709V0.925781Z"
                        fill="#3F628B"
                      />
                    </svg>
                    {addLoading && (
                      <CircularProgress
                        sx={{
                          color: "blue",
                          height: "20px !important",
                          width: "20px !important",
                          position: "absolute",
                          top: "5px",
                          // background: "var(--light-bg)",
                        }}
                      />
                    )}
                    Add a Custom Section
                  </Button>
                )}
                {addCustomInput && (
                  <>
                    <input
                      className={styles.input}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Enter a Topic e.g. Conclusion, Security & Privacy, Setup & Scalability"
                    />
                    <Button
                      id="addCustomSectionBtn"
                      disabled={addLoading}
                      onClick={handleAddAnalyze}
                      variant="outlined"
                      className="hover:scale-105 hover:origin-left"
                      sx={{
                        height: "36px",
                        borderWidth: "0.429px !important",
                        borderRadius: "7px",
                        border: "0.429px solid #3F628B",
                        background: "transparent",
                        color: ColorPattern[colorMode].borderBottom2,
                        padding: "0px 10px",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        textTransform: "none",
                      }}
                    >
                      <svg
                        style={{
                          marginRight: "0px",
                          width: "11px",
                          height: "11px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.65709 0.925781C4.65709 0.649639 4.43323 0.425781 4.15709 0.425781C3.88094 0.425781 3.65709 0.649639 3.65709 0.925781V4.0827H0.5C0.223858 4.0827 0 4.30656 0 4.5827C0 4.85884 0.223858 5.0827 0.5 5.0827H3.65709V8.24007C3.65709 8.51621 3.88094 8.74007 4.15709 8.74007C4.43323 8.74007 4.65709 8.51621 4.65709 8.24007V5.0827H7.81429C8.09043 5.0827 8.31429 4.85884 8.31429 4.5827C8.31429 4.30656 8.09043 4.0827 7.81429 4.0827H4.65709V0.925781Z"
                          fill="#3F628B"
                        />
                      </svg>
                      {addLoading && (
                        <CircularProgress
                          sx={{
                            color: "blue",
                            height: "20px !important",
                            width: "20px !important",
                            position: "absolute",
                            top: "5px",
                            background: "var(--light-bg)",
                          }}
                        />
                      )}
                      Add & Analyze
                    </Button>
                  </>
                )}
              </>
            ))}
        </div>
      )}
      {!previewEnabled && editContent && (
        <EditAnalysisModal
          title={activeTab}
          openModal={editContent}
          closeModal={() => setEditContent(false)}
          markdownData={textareaContent}
          setMarkdownData={setTextareaContent}
          disableBtn={sectionLoading}
          saveFunction={handleContentUpdate}
          discardFunction={resetContent}
        />
      )}
    </section>
  );
};

export default TabSectionTemplate;
