import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import NoData from '../../Components/Target/NoData';
import AgeProgressBar from '../../Components/TargetCharts/AgeCharts';
import SexRatioChart from '../../Components/TargetCharts/SexRatioCharts';
import CountryPieChart from '../../Components/TargetCharts/CountryCharts';

const WebTrafficPage = ({ data }) => {
	const groupDataByYear = (data) =>
		data?.reduce((acc, item) => {
			const { year, ...rest } = item; // Extract year and other data
			if (!acc[year]) acc[year] = []; // Initialize an array for the year if not exists
			acc[year].push(rest); // Push the rest of the data into the corresponding year
			return acc;
		}, {});

	const year = '2024';

	let monthlyChart = null;
	const updateData = () => {
		if (
			data &&
			data.website_traffic &&
			data.website_traffic.total_website_visits_by_month
		) {
			monthlyChart = groupDataByYear(
				data?.website_traffic?.total_website_visits_by_month
			);
		}
	};

	updateData();

	function formatNumberWithSuffix(num) {
		if (typeof num !== 'number' || isNaN(num)) {
			throw new Error('Input must be a valid number.');
		}

		const absNum = Math.abs(num);
		let suffix = '';
		let formattedNum = num;

		if (absNum >= 1_000_000_000) {
			formattedNum = num / 1_000_000_000;
			suffix = 'B';
		} else if (absNum >= 1_000_000) {
			formattedNum = num / 1_000_000;
			suffix = 'M';
		} else if (absNum >= 1_000) {
			formattedNum = num / 1_000;
			suffix = 'K';
		}

		return `${formattedNum.toLocaleString('en', {
			maximumFractionDigits: 2,
		})}${suffix}`;
	}

	console.log(monthlyChart[year]);

	const formatMonth = (month) => String(month).toUpperCase().substring(0, 3);

	return (
		<div className='flex flex-col w-full h-[1056px]  justify-start items-start bg-black relative px-16 py-12'>
			<div className='text-white flex flex-col justify-start items-start gap-5 w-full'>
				<h3 className='text-white font-bold text-[60px]'>WEB TRAFFIC</h3>
				<div className='w-full h-auto flex flex-col gap-2'>
					<div className='w-full h-auto flex flex-col justify-center gap-2'>
						<p className='text-gray-500 text-xs font-normal'>
							Monthly Website Visits
						</p>
						<div className='w-full flex flex-row gap-10 items-center'>
							<p className='text-[--dark-text] text-2xl font-medium leading-relaxed'>
								{data &&
									data.website_traffic &&
									data.website_traffic.monthly_visits &&
									formatNumberWithSuffix(
										data?.website_traffic?.monthly_visits || 0
									)}
							</p>
							<p className='text-green-400 text-xs font-normal'>
								{data?.website_traffic?.monthly_visits_change}%{' '}
								<span className='text-gray-500 font-light text-xs tracking-[1.6px]'>
									VS LAST MONTH
								</span>
							</p>
						</div>
					</div>
					{monthlyChart &&
					monthlyChart[year] &&
					monthlyChart[year]?.length > 0 ? (
						<div className='w-full h-auto min-h-60 2xl:h-2/3'>
							<ResponsiveContainer width='100%' height='90%' minHeight={240}>
								<LineChart
									width={500}
									height={300}
									data={monthlyChart[year]}
									margin={{
										top: 16,
										right: 30,
										left: 16,
										bottom: 5,
									}}>
									<XAxis
										tickLine={false}
										dataKey='date'
										tickFormatter={formatMonth}
										tickMargin={10}
										tick={{ fill: '#615E83', fontSize: 12 }}
										stroke='#E5E5EF'
									/>
									<YAxis hide tickFormatter={formatNumberWithSuffix} />
									<Line
										type='monotone'
										dataKey='total_website_visits'
										stroke='#0069E5'
										strokeWidth={2}
										dot={false}
										activeDot={{ r: 6 }}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					) : (
						<div className='w-full h-auto min-h-[300px] 2xl:h-2/3'>
							<NoData />
						</div>
					)}
				</div>
				<div className='w-full flex flex-col gap-3'>
					{data &&
						data.website_traffic &&
						data.website_traffic.visits_by_age &&
						Object.entries(data?.website_traffic?.visits_by_age)?.length >
							0 && (
							<div className='w-full'>
								<AgeProgressBar ageData={data?.website_traffic.visits_by_age} />
							</div>
						)}
					{data &&
						data.website_traffic &&
						data.website_traffic.visits_by_gender &&
						Object.entries(data?.website_traffic?.visits_by_gender)?.length >
							0 && (
							<div className='w-full '>
								<SexRatioChart
									rawData={data?.website_traffic.visits_by_gender}
								/>
							</div>
						)}
					{data &&
						data.website_traffic &&
						data.website_traffic.visitors_by_country &&
						data?.website_traffic?.visitors_by_country?.length > 0 && (
							<div className='w-full h-auto flex flex-row rounded border border-[--secondary-border]'>
								<CountryPieChart
									data={data?.website_traffic.visitors_by_country}
								/>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default WebTrafficPage;
