import React, { PureComponent, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Bar,
  BarChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const ReviewDistChart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  function transformData(input) {
    const dataEntries = Object.keys(input);

    // Find the maximum value
    const maxValue = Math.max(...dataEntries.map(([item]) => input[item]));
    // Determine the ceiling unit dynamically
    const ceilingUnit = Math.pow(10, Math.floor(Math.log10(maxValue)));

    // Calculate the ceiling value
    const ceilingValue = Math.ceil(maxValue / ceilingUnit) * ceilingUnit;

    // Transform the data
    return dataEntries.map((item) => ({
      name: item,
      value: input[item],
      empty: ceilingValue - input[item],
    }));
  }

  const verticaldata = transformData(data);
  const formatter = (value) => (value > 1000 ? `${value / 1000}k` : value);
  const CustomizedLabel = ({ x, y, width, name }) => (
    <text
      x={x + width}
      y={y}
      dy={-4}
      dx={-5}
      fontSize="10"
      fontFamily="sans-serif"
      fill={"#ffffff"}
      textAnchor="end"
    >
      {formatter(verticaldata.filter((item) => item.name === name)[0]?.value)}
    </text>
  );

  return (
    <div id="reviewScoreDistribution" className="w-full h-96">
      <ResponsiveContainer
        width="100%"
        height="60%"
        minHeight={100}
        maxHeight={360}
      >
        <BarChart
          layout="vertical"
          data={verticaldata}
          barCategoryGap={"12"}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        >
          <XAxis
            strokeWidth={0}
            type="number"
            tick={{ fill: "#aaa", fontSize: 12 }}
            tickFormatter={(tick) => (tick > 1000 ? `${tick / 1000}k` : tick)}
            domain={[
              0,
              (max) =>
                Math.max(max, verticaldata[verticaldata.length - 1].value),
            ]}
          />

          <YAxis
            type="category"
            dataKey="name"
            tick={{ fill: "#615E83", fontSize: 10 }}
            width={5}
            strokeWidth={0}
            dy={-14}
            dx={20}
          />
          <CartesianGrid
            horizontal={false}
            vertical={true}
            opacity={0.5}
            stroke="#E5E5EF"
            strokeDasharray={"3 3"}
            strokeLinecap="round"
          />
          {/* Bars */}
          <Bar
            radius={[3.5, 0, 0, 3.5]}
            dataKey="value"
            stackId="a"
            fill="#0069E5"
            onMouseEnter={(_, data) => setActiveIndex(data)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            {verticaldata.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={index === activeIndex ? "#0069E5" : "#9291A5"}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <Bar
            radius={[0, 3.5, 3.5, 0]}
            dataKey="empty"
            stackId="a"
            fill="#F0E5FC"
            onMouseEnter={(_, data) => setActiveIndex(data)}
            onMouseLeave={() => setActiveIndex(null)}
            label={<CustomizedLabel />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ReviewDistChart;
