import React from 'react';
import './index.css';

const ProgressItem = ({ label, value, max, color }) => {
    const isUnlimited =  max === 0;
    const percentage = isUnlimited ? 100 : (value / max) * 100;

    return (
        <div className="progress-item">
            <div className="progress-item-header">
                <div className="progress-item-label">{label}</div>
                <div className="progress-item-value">
                    {isUnlimited ? 'Unlimited' : `${value}/${max}`}
                </div>
            </div>
            <div className="progress-item-container">
                <div
                    className="progress-item-fill"
                    style={{
                        width: `${percentage}%`,
                        backgroundColor: color || (isUnlimited ? '#3F628B' : undefined)
                    }}
                />
            </div>
        </div>
    );
};

export default ProgressItem;