import { ArrowDown01Icon } from "@hugeicons/react";
import { MenuItem, Select } from "@mui/material";
import React, { useRef } from "react";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

const YearDropdown = ({ year, setYear, data }) => {
  const { colorMode } = useTheme();
  const years = Object?.keys(data)?.sort((a, b) => b - a);
  return (
    years?.length > 0 && (
      <Select
        size="small"
        id="select"
        value={String(year)}
        IconComponent={() => (
          <ArrowDown01Icon
          className="absolute w-3 h-3 right-0 -z-10"
            style={{ marginRight: "6px" }}
            size={16}
            color={colorMode == "dark" ? "#e1e1e1" : "#1e1e1e"}
          />
        )}
        sx={{
          color: "var(--dark-text)",
          fontSize: "12px",
          fontWeight: "400",
          background: "#F8F8FF30",
          height: "26px",
          borderRadius: "13px",
          zIndex: "6",

          "& #select": {
            padding: "0px 32px 0 14px",
            lineHeight: "26px",
          },
        }}
        onChange={(e) => setYear(e.target.value)}
      >
        {years?.map((item) => (
          <MenuItem
            key={item}
            sx={{ fontSize: "12px", fontWeight: "400" }}
            value={String(item)}
          >
            {String(item)}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

export default YearDropdown;
