import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// Sample data for male and female percentages

const COLORS = ["#4A3AFF", "#FF718B"]; // Male: Blue, Female: Orange

const SexRatioChart = ({ rawData }) => {
  function convertToSexRatioData(data) {
    return [
      { name: "Male", value: data.male_percentage },
      { name: "Female", value: data.female_percentage },
    ];
  }

  const data = convertToSexRatioData(rawData);

  return (
    <div className="w-full h-full">
      <div className="text-white flex justify-between items-center mb-3">
        <span className="font-semibold text-xs">Visits by Gender</span>
      </div>
      <hr />
      <div className="flex flex-row items-center justify-between mt-2 flex-wrap">
        <div className="flex flex-row items-center justify-start gap-2">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: "#4A3AFF" }}
          />
          <p className="text-white text-[10px]">
            {data[0].name} : {data[0].value}{" "}
          </p>
        </div>
        <div className="flex flex-row items-center justify-start gap-2">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: "#FF718B" }}
          />
          <p className="text-white text-[10px]">
            {data[1].name} : {data[1].value}
          </p>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="60%" maxHeight={100}>
        <PieChart width={104} height={50}>
          <Pie
            data={data}
            cx="50%" // Center X
            cy="100%" // Center Y at the bottom for a semi-circle
            startAngle={180} // Start from the left
            endAngle={0} // End at the right
            innerRadius={44} // Adjust the donut hole size
            outerRadius={52} // Adjust the chart size
            paddingAngle={5} // Add spacing between segments
            dataKey="value" // Key to access data
          >
            {data.map((entry, index) => (
              <Cell
                radius={10}
                stroke="none"
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
export default SexRatioChart;
