import React, { useContext } from 'react';
import styles from './index.module.css';
import Stars from '../../assets/icons/stars.svg';
import Disabled from '../../assets/icons/disabled.svg';
import Dot from '../../assets/icons/dot.svg';
import Pause from '../../assets/icons/pause.svg';
import Activity from '../../assets/icons/activity.svg';
import CompetitorRounds from '../../assets/icons/competitorround.svg';
import { useNavigate } from 'react-router-dom';
import { OutpointContext } from '../../Context/OutpointsContext/Index';
import { SET_SINGLE_ROUND } from '../../Context/OutpointsContext/action';
import AccessBlocked from '../Common/AccessBlocked';
import { useTheme } from '../../Context/ThemContext/ThemeContext';

const RoundCard = ({ item }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(OutpointContext);
  const roundIdData = item?.round_id;
  const roundName = item?.round_name;
  const { colorMode } = useTheme();
  return (
    <div
      onClick={() => {
        if (!item?.subscription_lock) {
          dispatch({ type: SET_SINGLE_ROUND, payload: item });
          localStorage.setItem("round", JSON.stringify(item));
          navigate(`/round/${roundName}?round_id=${roundIdData}`);
        }
      }}
      // className={styles.frameParent}
      className={`${styles.frameParent} ${colorMode === 'dark' ? styles.darkShadow : styles.lightShadow
        }`}>

      <AccessBlocked locked={item?.subscription_lock} />
      <div className={styles.frameWrapper}>
        <div className={styles.roundNameParent}>
          <div className={styles.roundName}>{item.round_name}</div>
          <div className={styles.vectorParent}>
            <img className={styles.vectorIcon} alt="" src={Dot} />
            <img className={styles.vectorIcon} alt="" src={Dot} />
            <img className={styles.vectorIcon} alt="" src={Dot} />
          </div>
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.starsParent}>
            <img className={styles.starsIcon} alt="" src={Stars} />
            <div className={styles.foundationNameWrapper}>
              <div className={styles.roundName}>
                {item?.foundation?.foundation_name}
              </div>
            </div>
          </div>
          <div className={styles.vs}>Vs.</div>
          <div className={styles.comet01Parent}>
            <img className={styles.starsIcon} alt="" src={CompetitorRounds} />
            <div className={styles.roundName}>
              {item?.competitor?.competitor_name}
            </div>
          </div>
        </div>
        <div className={styles.trackStatusParent}>
          <div className={styles.trackStatus}>Track Status:</div>
          <div className={styles.activity01Parent}>
            <img
              className={styles.activity01Icon}
              alt=""
              src={
                item?.tracking_status?.toLowerCase() === "disabled"
                  ? Disabled
                  : item?.tracking_status?.toLowerCase() === "active"
                    ? Activity
                    : item?.tracking_status?.toLowerCase() === "pause"
                      ? Pause
                      : Pause
              }
            />
            <div className={styles.active}>{item.tracking_status}</div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.competitorTrackFrequencyParent}>
            <div className={styles.trackStatus}>
              Track Frequency:
            </div>
            <div className={styles.active}>
              {item?.tracking_frequency?.[0]?.frequency_enum}
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.lastUpdatedParent}>
            <div className={styles.trackStatus}>Last Updated:</div>
            <div className={styles.active}>{item?.last_updated}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundCard;
