import React, { useContext, useEffect, useState } from 'react';
import Image1 from '../../../assets/icons/image 1.png';
import Arrow from '../../../assets/icons/arrow-left-double.svg';
import CompetitionReview from '../../../Components/NewRound/CompetitionReview';
import {
	Select,
	MenuItem,
	Button,
	CircularProgress,
	Modal,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';
import { CustomSwitch } from '../../../Components/Common/Switch';
import stylestwo from './index.module.css';
import {
	DeleteConfigurationModal,
	DeleteConfigurationModalSuccess,
} from '../../../Components/NewRound/SuccessRoundModal';
import { PrimaryModal } from '../../../Components/Common/PrimaryModal';
import {
	deleteRound,
	getCurrentSubscriptions,
	getFrequencyMetaData,
	getUserLimit,
	updateCompetitor,
	updateFoudation,
	updateRound,
	createSchedule,
	validateCronExpression,
} from '../../../services';
import { useNavigate } from 'react-router-dom';
import {
	SET_API_LOADING,
	SET_FOUNDATION_ONLY,
	SET_FOUNDATION_ONLY_RESET,
	SET_PATH_NAME,
	UPDATE_ROUNDS_DASHBOARD,
} from '../../../Context/OutpointsContext/action';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import { StyleTwoTone } from '@mui/icons-material';
import RoundForm from '../../../Components/NewRound/RoundForm';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { toast } from 'react-toastify';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { SubscriptionFeatures } from '../../../constant/constants';
import TrackAccessModal from '../../../Components/Common/TrackAccessModal';
import { pink } from '@mui/material/colors';
import { SubscriptionContext } from '../../../Context/SubscriptionContext';

const validateBtnStates = {
	DEFAULT: 'Validate',
	LOADING: (
		<span className='flex gap-2 items-center'>
			<CircularProgress size={15} color='inherit' /> Verifying
		</span>
	),
	SUCCESS: 'Valid',
	ERROR: 'Invalid',
};

const Configurations = ({ setUpdateSingleRound, textColor }) => {
	const {
		state,
		state: { pathName, foundation_page, apiLoading },
	} = useContext(OutpointContext);
	const { fetchSubscriptionData } = useContext(SubscriptionContext);
	const item = state?.singleRound;
	console.log(item);
	const { colorMode } = useTheme();
	const { dispatch } = useContext(OutpointContext);
	const [lable, setLable] = useState('');
	const [show, setShow] = useState(false);
	const [isUltra, setIsUltra] = useState(false);
	const [scheduleMethod, setScheduleMethod] = useState(
		(item?.track_configuration_mode == 1 ? 'scheduled' : 'cron') ?? 'scheduled'
	);
	const [cronVerifyStatus, setCronVerifyStatus] = useState(
		item?.track_configuration_mode == 1
			? validateBtnStates.DEFAULT
			: validateBtnStates.SUCCESS
	);
	const [cronText, setCronText] = useState(item?.cron_expression || '');
	const [nextDate, setNextDate] = useState(item?.next_trigger_date ?? null);
	const [showModal, setShowModal] = useState(false);
	const [frequencyChoices, setFrequencyChoices] = useState([]);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [updateActionModal, setUpdateActionModal] = useState(false);
	const [checked, setChecked] = useState(item?.tracking_competitor || false);
	const [checkedTwo, setCheckedTwo] = useState(
		item?.tracking_foundation || false
	);
	const [deteleModal, setDeleteModal] = useState(false);
	const [deteleSuccessModal, setDeleteSuccessModal] = useState(false);
	const [roundName, setRoundName] = useState(item?.round_name || '');
	const [select, setSelect] = useState(item?.tracking_frequency?.[0]);
	const getFrequency = async () => {
		try {
			let res = await getFrequencyMetaData();
			setFrequencyChoices(res?.data);
			if (!select) {
				setSelect(res?.data?.[0]);
			}
		} catch (err) {
			console.log(err);
		}
	};
	const getSubscriptionPlan = async () => {
		const res = await getCurrentSubscriptions('http://localhost:5173/');
		if (
			!['starter', 'advanced', 'elevate'].includes(
				String(res?.data?.current_tier)?.toLowerCase()
			)
		) {
			setIsUltra(true);
		}
	};

	useEffect(() => {
		let isGet = true;
		getSubscriptionPlan();
		if (isGet) getFrequency();

		return () => (isGet = false);
	}, []);

	const handleOptionChange = (e) => {
		setSelect(
			frequencyChoices.find((item) => item.frequency_enum === e.target.value)
		);
		setShow(true);
	};

	const handleDeleteRound = async () => {
		setDeleteLoading(true);
		try {
			let res = await deleteRound(item.round_id);
			if (res?.message) {
				setDeleteModal(false);
				setDeleteSuccessModal(true);
				console.log(res);

				dispatch({
					type: UPDATE_ROUNDS_DASHBOARD,
					payload: 1,
				});
			}
			if (res?.detail[0]?.msg) {
				toast.error(res.detail[0].msg);
			}
		} catch (err) {
			console.log(err);
		}
		setDeleteLoading(false);
		fetchSubscriptionData();
	};

	const navigateToDeleteRound = () => {
		setDeleteSuccessModal(false);
		navigate('/rounds');
	};

	const EditRound = async (id) => {
		setLable(id);

		dispatch({ type: SET_PATH_NAME, payload: '/foundations' });
		if (id === 'competitor') {
			let obj = {
				...item.competitor,
				foundation_name: item?.competitor?.competitor_name,
				foundation_mode: item?.competitor?.competitor_mode,
			};
			dispatch({
				type: SET_FOUNDATION_ONLY,
				payload: obj,
			});
		} else {
			dispatch({
				type: SET_FOUNDATION_ONLY,
				payload: { ...item.foundation },
			});
		}
		setOpenModal(true);
	};

	const handleSaveEdit = async () => {
		dispatch({ type: SET_API_LOADING, payload: true });

		try {
			if (pathName === '/foundations') {
				let obj = { ...foundation_page };

				if (obj.foundation_mode == 1) {
					obj.description = null;
				} else {
					obj.url = null;
					obj.scrape_mode = null;
					obj.additional_details = null;
				}
				let data;
				if (lable === 'competitor') {
					let newObj = {
						...obj,
						competitor_name: obj.foundation_name,
						competitor_mode: obj.foundation_mode,
					};

					data = await updateCompetitor(
						newObj,
						obj.competitor_id,
						item.foundation.foundation_id
					);
				} else {
					data = await updateFoudation(obj.foundation_id, obj);
				}

				if (data?.data) {
					toast.success(data.message);
					// setSaveEditCount(saveEditCount + 1);
					setUpdateSingleRound((prev) => prev + 1);
					setOpenModal(false);
				} else {
					toast.error(data?.detail?.[0]?.msg);
				}
			}
		} catch (err) {
			console.log(err);
		}
		dispatch({
			type: SET_FOUNDATION_ONLY_RESET,
			payload: {
				foundation_name: '',
				foundation_mode: 1,
				url: [],
				scrape_mode: 'singlepage',
				additional_details: '',
				description: '',
			},
		});
		dispatch({ type: SET_PATH_NAME, payload: '/' });

		dispatch({ type: SET_API_LOADING, payload: false });
	};

	const handleSave = async () => {
		dispatch({ type: SET_API_LOADING, payload: true });
		try {
			if (roundName.length == 0) {
				toast.error('Round Name is required');
				throw new Error('Round Name is required');
			}
			let name_changed = false;
			if (roundName.trim() != item.round_name.trim()) name_changed = true;

			let obj = {
				round_name: roundName,
				track_competitor: checked,
				track_foundation: checkedTwo,
			};
			if (scheduleMethod == 'scheduled') {
				obj.track_configuration_mode = 1;
				if (obj.track_competitor == true)
					obj.track_frequency = select?.frequency_id;
				else obj.track_frequency = null;
			} else {
				obj.track_configuration_mode = 2;
				obj.cron_expression = cronText;
			}

			let res = await updateRound(item.round_id, obj);
			if (res && obj.track_competitor == true) {
				await createSchedule(item.round_id);
			}
			setUpdateSingleRound((prev) => prev + 1);
			setShow(false);
			dispatch({ type: SET_API_LOADING, payload: false });
			if (name_changed) {
				dispatch({
					type: UPDATE_ROUNDS_DASHBOARD,
					payload: 1,
				});
				navigate(`/round/${roundName}?round_id=${item.round_id}`);
			}
		} catch (err) {
			console.log(err);
			setShow(false);
			dispatch({ type: SET_API_LOADING, payload: false });
		}
	};

	const handleTrackSwitch = async () => {
		const access = await getUserLimit(SubscriptionFeatures.TRACK);
		if (access) {
			setChecked(!checked);
			setShow(true);
		} else setShowModal(true);
	};

	const handleVerifyCron = async () => {
		setCronVerifyStatus(validateBtnStates.LOADING);
		const res = await validateCronExpression(cronText);
		if (res?.next_trigger_date) {
			setNextDate(res?.next_trigger_date);
			setCronVerifyStatus(validateBtnStates.SUCCESS);
		} else {
			toast.error(res?.detail);
			setCronVerifyStatus(validateBtnStates.ERROR);
		}
	};

	return (
		<>
			<Modal
				styles={'width:300px'}
				open={showModal}
				onClose={() => setShowModal(false)}>
				<TrackAccessModal closeModal={() => setShowModal(false)} />
			</Modal>

			{/* {Edit Foundation Sources and Edit Competitor Sources Modal} */}

			<PrimaryModal
				primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
				title={`Edit ${lable}`}
				open={openModal}
				setOpen={setOpenModal}>
				<div className={stylestwo.currentModal}>
					<RoundForm
						style={{
							height: '85%',
						}}
						isThird={false}
						des={true}
						RoundTitle={'jhgfds'}
					/>
					<div className={stylestwo.buttonActions}>
						<Button
							onClick={() => {
								setOpenModal(false);
								setEdit(false);
							}}
							variant='outlined'
							sx={{ margin: '10px' }}
							className='hover:scale-105'>
							{' '}
							Back{' '}
						</Button>
						<Button
							variant='contained'
							sx={{ margin: '10px' }}
							onClick={handleSaveEdit}
							disabled={apiLoading}
							className='hover:scale-105'>
							{apiLoading && (
								<CircularProgress
									sx={{
										color: 'white',
										height: '10px !important',
										width: '10px !important',
									}}
								/>
							)}
							Save
						</Button>
					</div>
				</div>
			</PrimaryModal>
			<div className={stylestwo.analysis__wrapper}>
				<PrimaryModal
					primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
					title={' '}
					open={deteleModal}
					setOpen={setDeleteModal}
					modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
					<DeleteConfigurationModal
						setDeleteModal={setDeleteModal}
						setDeleteSuccessModal={setDeleteSuccessModal}
						handleDeleteRound={handleDeleteRound}
						deleteLoading={deleteLoading}
						roundName={state?.singleRound?.round_name}
					/>
				</PrimaryModal>
				<PrimaryModal
					primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
					title={' '}
					open={deteleSuccessModal}
					setOpen={navigateToDeleteRound}
					modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
					<DeleteConfigurationModalSuccess
						navigateToDeleteRound={navigateToDeleteRound}
					/>
				</PrimaryModal>

				<div className={stylestwo.configuration}>
					<div className={stylestwo.configuration__topbox}>
						<div className='w-full flex gap-5 items-center self-stretch'>
							<span>Round Name</span>
							<div className={stylestwo.configuration_input}>
								<input
									required
									type='text'
									onChange={(e) => {
										setRoundName(e.target.value);
										setShow(true);
									}}
									value={roundName}
								/>
							</div>
						</div>
						{show && (
							<div className={stylestwo.saveDiv}>
								<Button
									onClick={() => {
										setUpdateSingleRound((prev) => prev + 1);
										setShow(false);
									}}
									variant='outlined'
									sx={{ margin: '10px', textTransform: 'none' }}
									className='hover:scale-105'>
									Discard changes
								</Button>
								<Button
									onClick={() => handleSave()}
									variant='contained'
									sx={{ margin: '10px', textTransform: 'none' }}
									disabled={
										apiLoading ||
										(scheduleMethod == 'cron' &&
											cronVerifyStatus != validateBtnStates.SUCCESS)
									}
									className='hover:scale-105'>
									{apiLoading && (
										<CircularProgress
											sx={{
												color: 'white',
												height: '10px !important',
												width: '10px !important',
											}}
										/>
									)}
									Save changes
								</Button>
							</div>
						)}
					</div>

					<div className={stylestwo['configuration__enable-wrapper']}>
						{checked ? (
							<span
								style={{
									marginRight: '15px',
								}}>
								Track Frequency
							</span>
						) : (
							<>
								<span
									className={`${
										colorMode == 'dark'
											? 'text-[#e1e1e1]/90'
											: 'text-[#1e1e1e]/90'
									} text-[13px] font-medium font-['Inter'] inline-block`}>
									Automatically Track Updates from Competitor Sources
								</span>
								<span className="text-[#797b7e] text-[11px] font-medium font-['Inter'] inline-block ml-1 mr-5">
									(Optional)
								</span>
							</>
						)}
						<CustomSwitch
							id='trackCompetitorToggle'
							checked={checked}
							onChange={handleTrackSwitch}
						/>
						<span
							className={stylestwo.checkedDisabled}
							style={{
								marginLeft: '15px',
								color: colorMode == 'light' ? '#141B34' : '#D0D5E6',
								textAlign: 'center',
								fontFamily: 'Inter',
								fontSize: '11px',
								fontStyle: 'normal',
								fontWeight: 400,
								lineHeight: 'normal',
							}}>
							{checked ? 'Enabled' : 'Disabled'}
						</span>
					</div>

					{checked && (
						<div className={stylestwo.configuration__para}>
							Sharbo AI tracks competitor URL updates and generates new analyses
							upon changes.
							<br /> You can choose to update the analysis on your embedded page
							accordingly.
						</div>
					)}
					{checked && (
						<>
							{isUltra && (
								<RadioGroup
									defaultValue={scheduleMethod}
									name='radio-buttons-group'
									sx={{
										flexDirection: 'row',
										marginBottom: '15px',
										gap: '40px',
										// fontSize: "14px !important",
										// " .css-ahj2mt-MuiTypography-root ": {
										//   fontSize: '13px !important'
										// }
										'&.css-7jv7yz-MuiFormControlLabel-root .MuiFormControlLabel-label':
											{
												fontSize: '13px !important',
											},
									}}
									onChange={(e) => {
										setScheduleMethod(e.target.value);
										setShow(true);
									}}>
									{['Scheduled', 'Cron'].map((opt) => (
										<>
											<FormControlLabel
												value={opt.toLowerCase()}
												control={
													<Radio
														sx={{
															color: colorMode === 'dark' ? '#fff' : '#1f1f1f',
															'& .MuiSvgIcon-root': {
																fontSize: '14px',
															},
														}}
														size='small'
													/>
												}
												label={opt}
												sx={{
													fontSize: '13px !important',
													fontFamily: 'Inter',
													fontWeight: '600',
													'& .MuiFormControlLabel-label': {
														fontSize: '13px !important',
													},
												}}
											/>
										</>
									))}
								</RadioGroup>
							)}
							{scheduleMethod == 'scheduled' ? (
								<>
									{' '}
									<Select
										labelId='filter-select-label'
										value={select?.frequency_enum}
										onChange={handleOptionChange}
										sx={{
											width: '171px',
											height: '40px',

											marginBottom: '15px',
											fontFamily: 'Inter',
											fontSize: '12px',
											fontStyle: 'normal',
											fontWeight: '400',
											lineHeight: 'normal',
											color: ColorPattern[colorMode].textColor,

											'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
												{
													borderColor: ColorPattern[colorMode].borderColor, // on hover
												},
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: ColorPattern[colorMode].borderColor, // in general
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												border: `1px solid ${ColorPattern[colorMode].borderColor}`, // when focused
											},
											'& .MuiSelect-icon': {
												color: ColorPattern[colorMode].textColor, // Change the icon color
											},
										}}>
										{frequencyChoices?.map((currentItem) => {
											return (
												<MenuItem value={currentItem?.frequency_enum}>
													{currentItem?.frequency_enum}
												</MenuItem>
											);
										})}
									</Select>
									<p className="text-[#797b7e] text-xs font-medium font-['Inter'] mb-4">
										Next Track Job:{' '}
										{select?.next_trigger_date ?? item?.next_trigger_date}
									</p>
								</>
							) : (
								<div className='w-full flex flex-col mb-4 gap-4'>
									<div className='flex items-center gap-2.5'>
										<input
											value={cronText}
											onChange={(e) => {
												setCronVerifyStatus(validateBtnStates?.DEFAULT);
												setCronText(e.target.value);
											}}
											className='h-10 w-64 px-2.5 py-3 bg-transparent rounded border border-[var(--secondary-border)] placeholder:text-[#797B7E] outline-none'
											placeholder='Cron expression input'
										/>
										<button
											disabled={
												cronVerifyStatus == validateBtnStates?.ERROR ||
												cronVerifyStatus == validateBtnStates?.SUCCESS ||
												cronVerifyStatus == validateBtnStates?.LOADING
											}
											onClick={handleVerifyCron}
											className={`h-10 w-32 flex items-center justify-center px-2.5 bg-transparent rounded border disabled:!cursor-default disabled:opacity-80 ${
												cronVerifyStatus == validateBtnStates?.ERROR
													? 'border-[#F24F4F] text-[#F24F4F]'
													: cronVerifyStatus == validateBtnStates?.SUCCESS
													? 'border-green-600 text-green-600'
													: 'border-[#0069E5] text-[#0069E5]'
											} text-xs font-medium font-['Inter']`}>
											{cronVerifyStatus}
										</button>
									</div>
									{nextDate && (
										<p className="text-[#797b7e] text-[11px] font-medium font-['Inter']">
											Next Track Job: {nextDate}
										</p>
									)}
								</div>
							)}
							<div className={stylestwo['configuration__enable-wrapper']}>
								<span style={{ marginRight: '20px' }}>
									Also track your Foundation sources?{' '}
									<span style={{ fontSize: '11px', color: '#797B7E' }}>
										(Applicable only with provided URLs)
									</span>
								</span>
								<CustomSwitch
									id='trackFoundationToggle'
									checked={checkedTwo}
									onChange={() => {
										setCheckedTwo(!checkedTwo);
										setShow(true);
									}}
								/>
								<span
									style={{
										marginLeft: '15px',
										color: colorMode == 'light' ? '#141B34' : '#D0D5E6',
										textAlign: 'center',
										fontFamily: 'Inter',
										fontSize: '11px',
										fontStyle: 'normal',
										fontWeight: 400,
										lineHeight: 'normal',
									}}>
									{checkedTwo ? 'Enabled' : 'Disabled'}
								</span>
							</div>
						</>
					)}
					<div className={stylestwo['configuration__card-wrapper']}>
						<div
							className={
								colorMode == 'light'
									? stylestwo.configuration__card + ' bg-[#A0A8BE1A]'
									: stylestwo.configuration__card + ' bg-[#474C5C1A]'
							}>
							<div style={{ fontSize: '14px', fontWeight: 600 }}>
								Foundation Name:
								<span
									style={{
										fontWeight: 400,
										color: '#797B7E',
										marginLeft: '5px',
									}}>
									{item?.foundation?.foundation_name}
								</span>
							</div>
							<div style={{ fontSize: '12px', fontWeight: 600 }}>
								Foundation Main Source:{' '}
								<span
				className="w-[206px] whitespace-nowrap inline-block overflow-hidden mt-px text-ellipsis"
									style={{
										color: '#0069E5',
										fontWeight: 400,
										textDecoration: 'underline',
										marginLeft: '5px',
									}}>
									{item?.foundation?.url?.[0]}
								</span>
							</div>
							<div style={{ fontSize: '12px', fontWeight: 600 }}>
								Additional Sources:{' '}
								<span
									style={{
										color: '#797B7E',
										fontWeight: 400,
										marginLeft: '5px',
									}}>
									{item?.foundation?.url?.length || 1} URLs &{' '}
									{item?.foundation?.additional_details ? 1 : 0} additional
									description
								</span>
							</div>
							<button
								onClick={() => EditRound('foundation')}
								className={`${stylestwo.editbtn} hover:scale-105`}>
								<span>Edit Foundation Sources</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'>
									<path
										d='M5.83988 4.14355L9.69699 8.0007L5.83984 11.8578'
										stroke='#3F628B'
										stroke-width='0.964286'
										stroke-miterlimit='16'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</button>
						</div>
						<div
							className={
								colorMode == 'light'
									? stylestwo.configuration__card + ' bg-[#A0A8BE1A]'
									: stylestwo.configuration__card + ' bg-[#474C5C1A]'
							}>
							<div style={{ fontSize: '14px', fontWeight: 600 }}>
								Competitor Name:
								<span
									style={{
										fontWeight: 400,
										color: '#797B7E',
										marginLeft: '5px',
									}}>
									{item?.competitor?.competitor_name}
								</span>
							</div>
							<div style={{ fontSize: '12px', fontWeight: 600 }}>
								Competitor Main Source:{' '}
								<span
				className="w-[206px] whitespace-nowrap inline-block overflow-hidden mt-px text-ellipsis"
									style={{
										color: '#0069E5',
										fontWeight: 400,
										textDecoration: 'underline',
										marginLeft: '5px',
									}}>
									{item?.competitor?.url?.[0]}
								</span>
							</div>
							<div style={{ fontSize: '12px', fontWeight: 600 }}>
								Additional Sources:{' '}
								<span
									style={{
										color: '#797B7E',
										fontWeight: 400,
										marginLeft: '5px',
									}}>
									{item?.competitor?.url?.length || 1} URLs &{' '}
									{item?.competitor?.additional_details ? 1 : 0} additional
									description
								</span>
							</div>
							<button
								onClick={() => EditRound('competitor')}
								className={`${stylestwo.editbtn} hover:scale-105`}>
								<span>Edit Competitor Sources</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'>
									<path
										d='M5.83988 4.14355L9.69699 8.0007L5.83984 11.8578'
										stroke='#3F628B'
										stroke-width='0.964286'
										stroke-miterlimit='16'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</button>
						</div>
					</div>
					<div className={stylestwo.buttonDiv}>
						<div>
							<button
								onClick={() => setDeleteModal(true)}
								className={`${stylestwo.deletebtn} hover:scale-105`}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='17'
									viewBox='0 0 16 17'
									fill='none'>
									<path
										d='M12.5921 4.32227L12.1937 10.767C12.0919 12.4135 12.041 13.2368 11.6283 13.8288C11.4242 14.1214 11.1615 14.3684 10.8568 14.554C10.2406 14.9294 9.41572 14.9294 7.76595 14.9294C6.11408 14.9294 5.28813 14.9294 4.67147 14.5533C4.36659 14.3674 4.10379 14.1199 3.8998 13.8268C3.48721 13.2339 3.43744 12.4095 3.3379 10.7606L2.94922 4.32227'
										stroke='#F24F4F'
										stroke-width='0.964286'
										stroke-linecap='round'
									/>
									<path
										d='M1.98438 4.32227H13.5558M10.3773 4.32227L9.93851 3.41695C9.64698 2.81558 9.50117 2.51489 9.24975 2.32736C9.19402 2.28576 9.13494 2.24876 9.07316 2.21672C8.79474 2.07227 8.46058 2.07227 7.79227 2.07227C7.10717 2.07227 6.76466 2.07227 6.4816 2.22277C6.41887 2.25613 6.359 2.29463 6.30263 2.33787C6.04829 2.533 5.90621 2.84469 5.62205 3.46808L5.23268 4.32227'
										stroke='#F24F4F'
										stroke-width='0.964286'
										stroke-linecap='round'
									/>
								</svg>
								<span>Delete Round</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Configurations;
