import {
	InformationSquareIcon,
	Link05Icon,
	Money01Icon,
	MoneySavingJarIcon,
	MoreIcon,
	StarsIcon,
} from '@hugeicons/react';
import React from 'react';
import SectionCard from '../../Components/Target/OverviewTab/SectionCard';

const OverviewPage = ({ data }) => {
	const basicCompanyInfo = [
		{
			label: 'Company Legal Name',
			data: data?.overview?.basic_info?.company_legal_name,
		},
		{ label: 'Country', data: data?.overview?.basic_info?.country },
		{ label: 'Status', data: data?.overview?.basic_info?.status },
		{ label: 'Company Size', data: data?.overview?.basic_info?.company_size },
		{ label: 'Founded Year', data: data?.overview?.basic_info?.founded_year },
		{ label: 'Industry', data: data?.overview?.basic_info?.industry },
		{ label: 'Type', data: data?.overview?.basic_info?.type },
		{
			label: 'Employee Count',
			data: data?.overview?.basic_info?.employee_count,
		},
		{
			label: 'Ownership Status',
			data: data?.overview?.basic_info?.ownership_count,
		},
		{
			label: 'Parent Company',
			data: data?.overview?.basic_info?.parent_company?.parent_company_name,
		},
	];

	return (
		<div className='flex flex-col w-full h-[1056px]  justify-start items-center bg-black relative px-16 py-24'>
			<div className='w-full flex flex-row justify-between items-start'>
				<div className='text-white flex flex-col justify-start items-start gap-5'>
					<h3 className='text-white font-bold text-[60px] -mt-12'>
						{data?.company_name}
					</h3>
					<div className=' w-auto max-w-max h-8 px-2.5 py-1 rounded border border-[#616161] inline-flex items-center gap-3 text-xs font-medium '>
						<StarsIcon
							size={16}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						<span className='text-xs font-light'>Industry:</span>
						<span className='text-xs font-medium'> {data?.industry}</span>
					</div>
					<div className='w-full flex h-6 items-center gap-2.5 text-xs  tracking-tight leading-6'>
						<span className='font-normal'>Data Freshness:</span>
						<span className='font-medium'>{data.data_freshness}</span>
					</div>
				</div>
				<div className='w-1/3'>
					{data?.overview?.basic_info?.data && (
						<div className='h-full flex flex-col gap-3'>
							<div className='w-full text-xs text-[--dark-text] flex gap-1.5'>
								<InformationSquareIcon
									size={14}
									color='#5092E0'
									variant='stroke'
									type='rounded'
								/>
								Basic Info
							</div>
							{basicCompanyInfo.map((info) => (
								<SectionCard
									key={info.label}
									label={info.label}
									data={info.data}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<div className='w-full h-full flex flex-col gap-5 mt-20'>
				<h3 className='text-white font-bold text-[60px]'>OVERVIEW</h3>
				<SectionCard
					icon={
						<Link05Icon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Main Website'
					data={data?.overview?.main_website ?? 'No intelligence available'}
				/>
				<div className='w-full h-auto'>
					<div className='w-full h-auto flex justify-start gap-1.5 items-center'>
						<MoreIcon
							size={14}
							color='#5092E0'
							variant='stroke'
							type='rounded'
						/>
						<h3 className='text-[--dark-text] text-xs font-medium'>
							Description
						</h3>
					</div>
					<div className="w-full min-h-16 grow mt-1 p-3.5 rounded border border-[--secondary-border] text-[--secondary-text] text-[10px] font-medium font-['Inter']">
						{data?.overview?.description ?? 'No intelligence available'}
					</div>
				</div>
				<SectionCard
					icon={
						<Money01Icon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue Range (Most Recent Financial Year)'
					data={data?.overview?.annual_revenue_range}
				/>
				<SectionCard
					icon={
						<MoneySavingJarIcon
							size={18}
							color={'#e1e1e1'}
							variant='stroke'
							type='rounded'
						/>
					}
					label='Annual Revenue (Most Recent Financial Year)'
					data={data?.overview?.annual_revenue}
				/>
				{/* Show only if data is present */}
				{/* {data?.overview?.updates?.length > 0 && <UpdatesSection />} */}
			</div>
		</div>
	);
};

export default OverviewPage;
