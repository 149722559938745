import React, { useContext, useEffect, useState } from 'react';
import stylestwo from './test.module.css';
import Image1 from '../../assets/icons/image 1.png';
import { useTheme } from '../../../src/Context/ThemContext/ThemeContext';
import { toast } from 'react-toastify';
import Arrow from '../../assets/icons/arrow-left-double.svg';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryModal } from '../../Components/Common/PrimaryModal';
import SuccessRoundModal, {
	PauseTrackingModal,
	ResumeTrackingModal,
} from '../../Components/NewRound/SuccessRoundModal/index.jsx';
import Trackandupdate from './TrackandUpdate/index.jsx';
import Configurations from './Configurations/index.jsx';
import { OutpointContext } from '../../Context/OutpointsContext/Index.jsx';
import Disabled from '../../assets/icons/disabled.svg';
import Dot from '../../assets/icons/dot.svg';
import Pause from '../../assets/icons/pause.svg';
import Activity from '../../assets/icons/activity.svg';
import {
	BASE_URL,
	getLogos,
	getMetaDescription,
	getPageScrape,
	getPageTitle,
	getSEO,
	getSingleRound,
	getUpdateAndTrack,
	getUserLimit,
	runTrackAndUpdate,
	updateTrackAndUpdate,
} from '../../services/index.js';
import { CircularProgress, Modal } from '@mui/material';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CompetitionReview from '../../Components/NewRound/CompetitionReview/index.jsx';
import { UseCopy } from '../../hooks/useCopy.js';
import { SubscriptionFeatures } from '../../constant/constants.js';
import { Copy01Icon, WorkflowSquare05Icon } from '@hugeicons/react';
import { SourceCodeIcon } from '@hugeicons/react';
import { Settings04Icon } from '@hugeicons/react';
import { WebDesign01Icon } from '@hugeicons/react';
import { ChartColumnIcon } from '@hugeicons/react';
import Analysis from '../../Components/NewRound/Analysis/analysisforRound.jsx';
import { SET_SINGLE_ROUND } from '../../Context/OutpointsContext/action.js';
import UsageLimitModal from '../../Components/Common/UsageLimitModal/index.jsx';
import { border, padding } from '@mui/system';
import { ColorPattern } from '../../constant/Color';
const tabsList = [
	{
		text: 'Track & Update',
		icon: (
			<WorkflowSquare05Icon size={18} color={'#3873BA'} variant={'stroke'} />
		),
	},
	{
		text: 'Analysis',
		icon: <ChartColumnIcon size={18} color={'#3873BA'} variant={'stroke'} />,
	},
	{
		text: 'Page',
		icon: <WebDesign01Icon size={18} color={'#3873BA'} variant={'stroke'} />,
	},
	{
		text: 'Configurations',
		icon: <Settings04Icon size={18} color={'#3873BA'} variant={'stroke'} />,
	},
	{
		text: 'Embedding',
		icon: <SourceCodeIcon size={18} color={'#3873BA'} variant={'stroke'} />,
	},
];

const accordionSummaryStyle = {
	color: '#7B8EA3',
	fontFamily: 'Inter',
	fontSize: '10px',
	fontStyle: 'normal',
	fontWeight: 500,
	lineHeight: 'normal',
	border: '0 !important',
	padding: '0 10px',
	minHeight: '36px !important',
	'& .MuiAccordionSummary-content': {
		margin: '0 !important',
	},
};

const SingleRound = ({ dark }) => {
	const location = useLocation();
	const nav = useNavigate();
	const { colorMode } = useTheme();
	const { copyText } = UseCopy();
	const { state, dispatch } = useContext(OutpointContext);
	const queryParams = new URLSearchParams(location.search);
	const roundId = queryParams.get('round_id');
	const iframeURL = window.location.origin;
	const iframeString = `<iframe src="${iframeURL}/analysis?round_id=${roundId}" style="width: 100%; height: ${window.innerHeight}px ; border: none;  scrolling=yes;  allowfullscreen=yes; width=320; height=240;  overflow: auto;
   -webkit-overflow-scrolling: touch; position: fixed; " ></iframe>`;
	// const iframeStringURL = `<iframe src="https://development.dgnwewa3yd4xc.amplifyapp.com/analysis?round_id=${roundId}" style="width: 100%; height: 500px; border: none;"></iframe>`;
	const codeToCopy = iframeString.trim();
	// const codeToCopyURL = iframeStringURL.trim();
	const iframeStringURL = `<iframe src="${iframeURL}/analysis?round_id=${roundId}" style="width: 100%; height: ${window.innerHeight}px ; border: none; scrolling=yes;  allowfullscreen=yes; width=320; height=240;  overflow: auto;
   -webkit-overflow-scrolling: touch; position: fixed; "></iframe>`;

	const urlToCopy = iframeStringURL.match(/src="([^"]+)"/)[1];

	const [loading, setLoading] = useState(false);
	const [accessDenied, setAccessDenied] = useState(false);
	const [updateSingleRound, setUpdateSingleRound] = useState(0);
	const [data, setData] = useState({});
	const [trackLoading, setTrackLoading] = useState(false);
	const [pauseLoading, setPauseLoading] = useState(false);
	const [previewState, setPreviewState] = useState(false);
	const [trackUpdatePayload, setTrackUpdatePayload] = useState(0);
	const [activeTab, setActive] = useState(4);
	const [trackStatusPaused, setTrackStatusPaused] = useState();
	const [logos, setLogos] = useState({});
	const [trackingFeatureEnabled, setTrackingFeatureEnabled] = useState(true);
	const [successModal, setSuccessModal] = useState(false);
	const [pauseModal, setPauseModal] = useState(false);
	const [resumeModal, setResumeModal] = useState(false);
	const [regenerationLoading, setRegenerationLoading] = useState(null);
	const [scrapeData, setScrapeData] = useState({});
	const [pageTitle, setPageTitle] = useState({});
	const [metaDescription, setMetaDescription] = useState({});
	const [previewDetails, setPreviewDetails] = useState({});

	const expandIcon = (
		<ExpandMoreIcon
			sx={{
				width: '18px',
				height: '18px',
				color: colorMode == 'light' ? '#1E1E1E' : '#E1E1E1',
			}}
		/>
	);

	useEffect(() => {
		const getLogosData = async () => {
			try {
				let res = await getLogos(roundId);
				if (res?.data) setLogos(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		getLogosData();
		getTrackAccess();
		getScrape();
		getpage();
		getMetaDes();
	}, []);

	useEffect(() => {
		getTrackAndUpdateData();
	}, [trackUpdatePayload]);

	useEffect(() => {
		getSingleRoundData();
	}, [updateSingleRound]);

	// Effect to handle tab change
	// useEffect(() => {
	// 	// Set preview to false when switching to any tab other than the one that shows Track and Update
	// 	if (activeTab !== 1) {
	// 		setPreviewState(false);
	// 	}
	// }, [activeTab]); // Runs whenever activeTab changes

	const getSingleRoundData = async () => {
		setLoading(true);
		try {
			let res = await getSingleRound(roundId);
			// console.log(res);
			if (res?.data) {
				setAccessDenied(res.data?.[0]?.subscription_lock);
				dispatch({ type: SET_SINGLE_ROUND, payload: res.data?.[0] });
				// console.log("pause_tracking", res.data?.[0]?.pause_tracking);

				setTrackStatusPaused(res.data?.[0]?.pause_tracking);
				localStorage.setItem('round', JSON.stringify(res.data?.[0]));
			}
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	const getTrackAndUpdateData = async () => {
		setTrackLoading(true);
		try {
			let res = await getUpdateAndTrack(roundId);
			if (res) {
				setData(res);
			} else {
				toast.error(res?.detail?.[0]?.msg);
			}
		} catch (err) {
			console.log(err);
		}
		setTrackLoading(false);
	};

	const handlePauseActive = async () => {
		setPauseLoading(true);
		try {
			let res = await updateTrackAndUpdate(roundId, !trackStatusPaused);
			if (res?.data) {
				if (trackStatusPaused) {
					setResumeModal(true);
				}
				setUpdateSingleRound((prev) => prev + 1);
				setTrackStatusPaused(res.data?.pause_tracking);
			} else {
				toast.error(res?.detail?.[0]?.msg);
			}
		} catch (err) {
			console.log(err);
		}
		setPauseLoading(false);
	};


	const handleCopy = () => {
		navigator.clipboard
			.writeText(codeToCopy)
			.then(() => {
				alert('Code copied to clipboard!');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};
	const handleCopyURL = () => {
		navigator.clipboard
			.writeText(urlToCopy)
			.then(() => {
				alert('Code copied to clipboard!');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};

	const getTrackAccess = async () => {
		try {
			const res = await getUserLimit(SubscriptionFeatures.TRACK);
			setTrackingFeatureEnabled(res);
		} catch (err) { }
	};

	const getScrape = async () => {
		// setRegenerationLoading("SEO");
		try {
			let res = await getSEO(roundId);
			if (res?.data) {
				setScrapeData(res);
			}
		} catch (err) { }
		setRegenerationLoading(null);
	};

	const getpage = async () => {
		setRegenerationLoading('page');
		try {
			let res = await getPageTitle(roundId);
			if (res?.data) {
				setPageTitle(res);
			}
		} catch (err) { }
		setRegenerationLoading(null);
	};

	const getMetaDes = async () => {
		setRegenerationLoading('meta');
		try {
			let res = await getMetaDescription(roundId);
			if (res?.data) {
				setMetaDescription(res);
			}
		} catch (err) { }
		setRegenerationLoading(null);
	};

	const truncateText = (text, limit = 15) => {
		if (!text) return '';
		return text.length > limit ? `${text.slice(0, limit)}...` : text;
	};

	const navigate = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const tab = searchParams.get('tab');
		if (tab) {
			setActive(tab);
		}
	}, [location]);

	const handleTabChange = (tabTitle) => {
		console.log(tabTitle);
		navigate(`?round_id=${roundId}&tab=${tabTitle}`);
	};

	// if (!logos?.foundation_logo || !logos?.competitor_logo) {
	// 	return null;
	// }

	const onError = (e) => {
		e.target.src = Image1;
	};


	return (
		<>
			<Modal
				styles={'width:300px'}
				open={accessDenied}
				onClose={() => nav('/', { replace: true })}>
				<UsageLimitModal closeModal={() => nav('/', { replace: true })} />
			</Modal>
			{loading && (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
						padding: '300px 0px',
					}}>
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<>
					{
						<>
							<PrimaryModal
								primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
								title={' '}
								open={successModal}
								setOpen={setSuccessModal}>
								<SuccessRoundModal
									trackinJob
									heading='Tracking job executed successfully'
								/>
							</PrimaryModal>
							<PrimaryModal
								primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
								title={' '}
								open={pauseModal}
								setOpen={setPauseModal}
								modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
								<PauseTrackingModal
									handlePauseActive={handlePauseActive}
									setPauseModal={setPauseModal}
									pauseLoading={pauseLoading}
								/>
							</PrimaryModal>
							<PrimaryModal
								primaryModalChildrenClass={stylestwo.addEnrichmentChildren}
								title={' '}
								open={resumeModal}
								setOpen={setResumeModal}
								modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
								<ResumeTrackingModal setResumeModal={setResumeModal} />
							</PrimaryModal>
							<div className={stylestwo.main__container}>
								<div className={stylestwo.container}>
									<div className={stylestwo.topSection}>
										<div className={stylestwo.leftColumn}>
											<div className={stylestwo.roundName}>
												{state?.singleRound?.round_name}
											</div>
											<div className={stylestwo.foundationContainer}>
												<div className={stylestwo.foundationText}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width={19}
														height={20}
														viewBox='0 0 19 20'
														fill='none'>
														<path
															d='M2.37109 10.0004C5.84837 10.0004 9.32564 6.52317 9.32564 3.0459C9.32564 6.52317 12.8029 10.0004 16.2802 10.0004C12.8029 10.0004 9.32564 13.4777 9.32564 16.955C9.32564 13.4777 5.84837 10.0004 2.37109 10.0004Z'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinejoin='round'
														/>
														<path
															d='M1.60156 15.7961C2.2455 15.7961 3.53338 14.5082 3.53338 13.8643C3.53338 14.5082 4.82126 15.7961 5.4652 15.7961C4.82126 15.7961 3.53338 17.084 3.53338 17.7279C3.53338 17.084 2.2455 15.7961 1.60156 15.7961Z'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinejoin='round'
														/>
														<path
															d='M12.418 4.59162C13.1907 4.59162 14.7362 3.04616 14.7362 2.27344C14.7362 3.04616 16.2816 4.59162 17.0543 4.59162C16.2816 4.59162 14.7362 6.13707 14.7362 6.9098C14.7362 6.13707 13.1907 4.59162 12.418 4.59162Z'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinejoin='round'
														/>
													</svg>
													<span>
														{' '}
														{/* {singleRound?.foundation?.foundation_name} */}
														{truncateText(
															state?.singleRound?.foundation?.foundation_name
														)}
													</span>
												</div>
												<div className={stylestwo.vsText}>Vs.</div>
												<div className={stylestwo.foundationText}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width={20}
														height={20}
														viewBox='0 0 20 20'
														fill='none'>
														<path
															d='M3.73272 8.49374C1.62034 10.6061 1.62034 14.0309 3.73272 16.1434C5.8451 18.2558 9.26997 18.2558 11.3823 16.1434L14.434 13.0916M9.95306 2.27344L5.62571 6.60076M17.603 9.92266L16.0575 11.4681'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinecap='round'
														/>
														<path
															d='M9.19565 10.679C10.101 11.5843 10.101 13.052 9.19565 13.9574C8.29036 14.8627 6.82256 14.8627 5.91726 13.9574C5.01196 13.052 5.01196 11.5843 5.91726 10.679C6.82256 9.77367 8.29036 9.77367 9.19565 10.679Z'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinecap='round'
														/>
														<path
															d='M5.91797 10.6803L11.4206 5.17725M14.3242 2.27344L12.9661 3.63168M17.6025 5.55241L12.4746 10.6803'
															stroke='#7CA1CB'
															strokeWidth='1.15909'
															strokeLinecap='round'
														/>
													</svg>
													<span>
														{' '}
														{truncateText(
															state?.singleRound?.competitor?.competitor_name
														)}
													</span>
												</div>
											</div>
										</div>
										<div className={stylestwo.rightColumn}>
											<div className={stylestwo.trackStatusContainer}>
												<span className={stylestwo.trackStatusText}>
													Track Status:
												</span>
												<button
													className={`${stylestwo.trackStatusButton} hover:scale-105`}>
													<img
														// className={styles.activity01Icon}
														alt=''
														src={
															state?.singleRound?.tracking_status === 'Disabled'
																? Disabled
																: state?.singleRound?.tracking_status ===
																	'Active'
																	? Activity
																	: Pause
														}
													/>

													<span className={stylestwo.activeText}>
														{state?.singleRound?.tracking_status}
													</span>
												</button>
											</div>
											<div className={stylestwo.lastTrackedContainer}>
												{state?.singleRound?.tracking_frequency && (
													<>
														<span className={stylestwo.lastTrackedText}>
															Last Tracked:
														</span>
														<span className={stylestwo.lastTrackedDate}>
															{state?.singleRound?.last_tracked || '-'}
														</span>
														<div className={stylestwo.divider}></div>
													</>
												)}
												{/* {state?.singleRound?.last_analysis_updated ? ( */}
												<>
													<span className={stylestwo.lastTrackedText}>
														Last Analysis Update:
													</span>
													<span className={stylestwo.lastTrackedDate}>
														{state?.singleRound?.last_analysis_updated || '-'}
													</span>
												</>
												{/* ) : (
                          ""
                        )} */}
											</div>
											<div className={stylestwo.lastTrackedContainer}>
												{state?.singleRound?.tracking_frequency && (
													<>
														<span className={stylestwo.lastTrackedText}>
															Track Frequency:
														</span>
														<span className={stylestwo.lastTrackedDate}>
															{
																state?.singleRound?.tracking_frequency?.[0]
																	?.frequency_enum
															}
														</span>
														<div className={stylestwo.divider}></div>
													</>
												)}
												{/* {state?.singleRound?.last_updated ? ( */}
												<>
													<span className={stylestwo.lastTrackedText}>
														Last Page Update:{' '}
													</span>
													<span className={stylestwo.lastTrackedDate}>
														{state?.singleRound.last_updated || '-'}
													</span>
												</>
												{/* // ) : (
                      //   ""
                      // )} */}
											</div>
										</div>
									</div>
								</div>
								<div className={stylestwo.buttonContainer}>
									{tabsList &&
										tabsList.map((tab, idx) => {
											return (
												<div
													key={idx} // Added a key for the list
													style={{
														cursor: 'pointer',
														display: 'flex',
														alignItems: 'center',
														marginRight: '16px',
													}} // Aligning content horizontally and adding space between items
													onClick={() => handleTabChange(idx)}
													className={` ${idx == activeTab ? stylestwo.active : ''
														} ${stylestwo.button} `}>
													<span style={{ marginRight: '8px' }}>{tab.icon}</span>{' '}
													{/* Margin between icon and text */}
													<span style={{ fontSize: '12px' }}>{tab.text}</span>
												</div>
											);
										})}
								</div>
								{activeTab == 4 && (
									<div className={stylestwo.bottomSection}>
										<div className={stylestwo.bottomTextContainer}>
											<div style={{ fontSize: '12px', }}>
												Copy and paste the code below to embed the product
												comparison analysis from 'Page' on your website
											</div>
										</div>
										<span>
											We recommend creating a dedicated landing page for this
											content. You can design your own heading and SEO; Sharbo
											AI provides additional suggestions below.
										</span>
										<div className={`${stylestwo.codebox} h-20 `}>
											<span
												className='inline-block bg-gradient-to-r from-[#3D87DD]  to-[#B83E3E] bg-clip-text text-transparent'
												style={{ fontSize: '14px' }}>
												{iframeString}
											</span>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'end',
												gap: '14px',
												width: '100%',
											}}>
											<CopyToClipboard
												onClick={handleCopyURL}
												text={urlToCopy}
												onCopy={() => alert('Code copied to clipboard!')}>
												<button
													className={`${stylestwo.btnthree} hover:scale-105`}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='17'
														height='16'
														viewBox='0 0 17 16'
														fill='none'>
														<path
															d='M6.41016 9.92941C6.41016 8.11115 6.41016 7.20202 6.97502 6.63713C7.53991 6.07227 8.44904 6.07227 10.2673 6.07227H10.9102C12.7284 6.07227 13.6375 6.07227 14.2024 6.63713C14.7673 7.20202 14.7673 8.11115 14.7673 9.92941V10.5723C14.7673 12.3905 14.7673 13.2997 14.2024 13.8645C13.6375 14.4294 12.7284 14.4294 10.9102 14.4294H10.2673C8.44904 14.4294 7.53991 14.4294 6.97502 13.8645C6.41016 13.2997 6.41016 12.3905 6.41016 10.5723V9.92941Z'
															stroke='#0069E5'
															stroke-width='0.964286'
															stroke-linecap='round'
															stroke-linejoin='round'
														/>
														<path
															d='M11.5529 6.07227C11.5514 4.17128 11.5227 3.18661 10.9693 2.5124C10.8625 2.38219 10.7431 2.26281 10.6129 2.15595C9.90164 1.57227 8.84498 1.57227 6.73158 1.57227C4.6182 1.57227 3.56151 1.57227 2.85029 2.15595C2.72009 2.2628 2.6007 2.38219 2.49384 2.5124C1.91016 3.22362 1.91016 4.28031 1.91016 6.39369C1.91016 8.50709 1.91016 9.56375 2.49384 10.275C2.60069 10.4052 2.72009 10.5246 2.85029 10.6314C3.52451 11.1848 4.50917 11.2135 6.41016 11.2151'
															stroke='#0069E5'
															stroke-width='0.964286'
															stroke-linecap='round'
															stroke-linejoin='round'
														/>
													</svg>
													<span style={{ color: '#0069E5', fontSize: '10px' }}>
														Copy shared URL
													</span>
												</button>
											</CopyToClipboard>
											<CopyToClipboard
												onClick={handleCopy}
												text={codeToCopy}
												onCopy={() => alert('Code copied to clipboard!')}>
												<button
													style={{ backgroundColor: '#0069E5', color: 'white' }}
													className={`${stylestwo.btnthree} hover:scale-105`}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='17'
														height='16'
														viewBox='0 0 17 16'
														fill='none'>
														<path
															d='M6.41016 9.92941C6.41016 8.11115 6.41016 7.20202 6.97502 6.63713C7.53991 6.07227 8.44904 6.07227 10.2673 6.07227H10.9102C12.7284 6.07227 13.6375 6.07227 14.2024 6.63713C14.7673 7.20202 14.7673 8.11115 14.7673 9.92941V10.5723C14.7673 12.3905 14.7673 13.2997 14.2024 13.8645C13.6375 14.4294 12.7284 14.4294 10.9102 14.4294H10.2673C8.44904 14.4294 7.53991 14.4294 6.97502 13.8645C6.41016 13.2997 6.41016 12.3905 6.41016 10.5723V9.92941Z'
															stroke='white'
															stroke-width='0.964286'
															stroke-linecap='round'
															stroke-linejoin='round'
														/>
														<path
															d='M11.5529 6.07227C11.5514 4.17128 11.5227 3.18661 10.9693 2.5124C10.8625 2.38219 10.7431 2.26281 10.6129 2.15595C9.90164 1.57227 8.84498 1.57227 6.73158 1.57227C4.6182 1.57227 3.56151 1.57227 2.85029 2.15595C2.72009 2.2628 2.6007 2.38219 2.49384 2.5124C1.91016 3.22362 1.91016 4.28031 1.91016 6.39369C1.91016 8.50709 1.91016 9.56375 2.49384 10.275C2.60069 10.4052 2.72009 10.5246 2.85029 10.6314C3.52451 11.1848 4.50917 11.2135 6.41016 11.2151'
															stroke='white'
															stroke-width='0.964286'
															stroke-linecap='round'
															stroke-linejoin='round'
														/>
													</svg>
													<span style={{ color: 'white', fontSize: '10px' }}>
														Copy Code
													</span>
												</button>
											</CopyToClipboard>
										</div>
										<hr
											className={`w-full border-t ${colorMode == 'dark'
												? 'border-white/10'
												: 'border-black/10'
												}`}
										/>
										<div className={`flex justify-between w-full `}>
											<div className={stylestwo.lastsection__left}>
												<p>Other Useful Resources:</p>
												<Accordion
													sx={{
														backgroundColor: 'transparent',
														border: '#556E8C66 0.5px solid',
														'&::before': {
															display: 'none',
														},
														boxShadow: 'none',
														borderRadius: '4px !important',
														margin: '0 !important', // Ensure no margin between accordions
														fontFamily: 'inter',
													}}>
													<AccordionSummary
														expandIcon={expandIcon}
														aria-controls={`bh-content`}
														id={`bh-header`}
														sx={accordionSummaryStyle}>
														Comparison Page SEO Optimizations
													</AccordionSummary>
													<AccordionDetails
														sx={{
															borderTop: '#556E8C66 0.5px solid',
															padding: '12px 10px',
														}}>
														<div className={stylestwo.bottomTextContainer}>
															<div className='text-[10px] font-normal'>
																Sharbo AI's Recommended SEO Keywords for This
																Page:
															</div>
															<button
																onClick={() => {
																	copyText(scrapeData?.data?.join(', '));
																}}
																className={`${stylestwo.btnthree} hover:scale-105`}>
																<Copy01Icon
																	size={16}
																	color={'#0069E5'}
																	variant={'stroke'}
																/>
																<span>Copy All</span>
															</button>
														</div>

														{colorMode === 'light' ? (
															<div className={stylestwo.chipsContainer}>
																{scrapeData?.data?.map((e) => (
																	<div className="h-5 px-2 py-[3.50px] bg-[#cbe0f9] rounded-sm justify-center items-center gap-[5px] inline-flex text-[#1e1e1e] text-[10px] font-normal font-['Inter'] tracking-tight">
																		{e}
																	</div>
																))}
															</div>
														) : (
															<div className={stylestwo.chipsContainer}>
																{scrapeData?.data?.map((e) => (
																	<div className="h-5 px-2 py-[3.50px] bg-[#0c1b2d] rounded-sm justify-center items-center gap-[5px] inline-flex text-[#e1e1e1] text-[10px] font-normal font-['Inter'] tracking-tight">
																		{e}
																	</div>
																))}
															</div>
														)}
													</AccordionDetails>
												</Accordion>
												<Accordion
													sx={{
														backgroundColor: 'transparent',
														border: '#556E8C66 0.5px solid',
														'&::before': {
															display: 'none',
														},
														borderRadius: '4px !important',
														boxShadow: 'none',
														margin: '0 !important',
														fontFamily: 'inter',
													}}>
													<AccordionSummary
														expandIcon={expandIcon}
														aria-controls={`bh-content`}
														id={`bh-header`}
														sx={accordionSummaryStyle}>
														Suggested Page Title
													</AccordionSummary>
													<AccordionDetails
														sx={{
															borderTop: '#556E8C66 0.5px solid',
															padding: '12px 10px',
														}}>
														<div className={stylestwo.bottomTextContainer}>
															<div
																style={{ fontSize: '12px', fontWeight: 400 }}>
																{pageTitle?.data}
															</div>
															<button
																style={{
																	border: 'solid 0.429px #0069E5',
																}}
																id='regenerateTitle'
																onClick={getpage}
																disabled={regenerationLoading == 'page'}
																className={`${stylestwo.btnthree} hover:scale-105`}>
																{regenerationLoading == 'page' && (
																	<CircularProgress
																		sx={{
																			height: '10px !important',
																			width: '10px !important',
																		}}
																	/>
																)}
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='17'
																	height='16'
																	viewBox='0 0 17 16'
																	fill='none'>
																	<path
																		d='M13.8047 3.82227H6.73326C4.34615 3.82227 2.55469 5.54836 2.55469 8.00084'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M2.875 12.1786H9.94643C12.3336 12.1786 14.125 10.4525 14.125 8'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M12.5195 2.21484C12.5195 2.21484 14.1267 3.39849 14.1267 3.822C14.1267 4.24551 12.5195 5.42913 12.5195 5.42913'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M4.16182 10.5723C4.16182 10.5723 2.55469 11.7559 2.55469 12.1794C2.55468 12.6029 4.16183 13.7866 4.16183 13.7866'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																</svg>
																<span
																	style={{
																		color: '#0069E5',
																	}}>
																	Regenerate
																</span>
															</button>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion
													sx={{
														backgroundColor: 'transparent',
														border: '#556E8C66 0.5px solid',
														'&:not(:last-child)': {
															borderBottom: 0,
														},
														'&::before': {
															display: 'none',
														},
														borderRadius: '4px !important',
														boxShadow: 'none',
														margin: '0 !important',
														fontFamily: 'inter',
													}}>
													<AccordionSummary
														expandIcon={expandIcon}
														aria-controls={`bh-content`}
														id={`bh-header`}
														sx={accordionSummaryStyle}>
														Meta/Header Description Suggestion
													</AccordionSummary>
													<AccordionDetails
														sx={{
															borderTop: '#556E8C66 0.5px solid',
															padding: '12px 10px',
														}}>
														<div className={stylestwo.bottomTextContainer}>
															<div
																style={{ fontSize: '12px', fontWeight: 400 }}>
																{metaDescription?.data}
															</div>
															<button
																id='regenerateMeta'
																style={{
																	border: 'solid 0.429px #0069E5',
																}}
																onClick={getMetaDes}
																disabled={regenerationLoading == 'meta'}
																className={`${stylestwo.btnthree} hover:scale-105`}>
																{regenerationLoading == 'meta' && (
																	<CircularProgress
																		sx={{
																			height: '10px !important',
																			width: '10px !important',
																		}}
																	/>
																)}
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='17'
																	height='16'
																	viewBox='0 0 17 16'
																	fill='none'>
																	<path
																		d='M13.8047 3.82227H6.73326C4.34615 3.82227 2.55469 5.54836 2.55469 8.00084'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M2.875 12.1786H9.94643C12.3336 12.1786 14.125 10.4525 14.125 8'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M12.5195 2.21484C12.5195 2.21484 14.1267 3.39849 14.1267 3.822C14.1267 4.24551 12.5195 5.42913 12.5195 5.42913'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																	<path
																		d='M4.16182 10.5723C4.16182 10.5723 2.55469 11.7559 2.55469 12.1794C2.55468 12.6029 4.16183 13.7866 4.16183 13.7866'
																		stroke='#0069E5'
																		stroke-width='0.857143'
																		stroke-linecap='round'
																		stroke-linejoin='round'
																	/>
																</svg>
																<span style={{ color: '#0069E5' }}>
																	Regenerate
																</span>
															</button>
														</div>
													</AccordionDetails>
												</Accordion>
											</div>
											{(logos?.foundation_logo && logos?.competitor_logo) ?
												(<div className={stylestwo.lastsection__right}>
													<p>Logos Extracted by Sharbo Al:</p>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '15px',
														}}>
														<span className={stylestwo.foundationText}>
															Foundation:
														</span>
														<img
															onError={onError}
															src={logos?.foundation_logo || Image1}
															style={{
																border: '1px solid rgba(0, 0, 0, 0.1)',

																width: '50px',
																objectFit: 'cover',
															}}
														/>
														<a
															href={logos?.foundation_logo || Image1}
															download={true}
															target='_blank'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='21'
																height='20'
																viewBox='0 0 21 20'
																fill='none'
																style={{ cursor: 'pointer' }}>
																<path
																	d='M7.97266 10.417L10.056 12.5003L12.1393 10.417M10.056 4.16699V11.993'
																	stroke='#0069E5'
																	stroke-width='1.25'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
																<path
																	d='M4.22266 15.833H15.8893'
																	stroke='#0069E5'
																	stroke-width='1.25'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
															</svg>
														</a>
													</div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '15px',
														}}>
														<span className={stylestwo.foundationText}>
															Competitor:
														</span>
														<img
															onError={onError}
															src={logos?.competitor_logo || Image1}
															style={{
																border: '1px solid rgba(0, 0, 0, 0.1)',
																width: '50px',
																objectFit: 'cover',
															}}
														/>
														<a
															href={logos?.competitor_logo || Image1}
															download={true}
															target='_blank'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='21'
																height='20'
																viewBox='0 0 21 20'
																fill='none'
																style={{ cursor: 'pointer' }}>
																<path
																	d='M7.97266 10.417L10.056 12.5003L12.1393 10.417M10.056 4.16699V11.993'
																	stroke='#0069E5'
																	stroke-width='1.25'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
																<path
																	d='M4.22266 15.833H15.8893'
																	stroke='#0069E5'
																	stroke-width='1.25'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
															</svg>
														</a>
													</div>
												</div>) : ''}
										</div>
									</div>
								)}
								{activeTab == 1 && (
									<div className={stylestwo.analysis__wrapper}>
										<Analysis
											setUpdateSingleRound={setUpdateSingleRound}
											previewState={previewState}
											setPreviewState={setPreviewState}
											setTrackUpdatePayload={setTrackUpdatePayload}
											previewDetails={previewDetails}
											setPreviewDetails={setPreviewDetails}
										/>
									</div>
								)}
								{activeTab == 0 && (
									<Trackandupdate
										setSuccessModal={setSuccessModal}
										reloadData={setTrackUpdatePayload}
										trackingFeatureEnabled={
											state?.singleRound?.tracking_status != 'Disabled' &&
											trackingFeatureEnabled
										}
										trackStatusPaused={trackStatusPaused}
										setResumeModal={setResumeModal}
										pauseLoading={pauseLoading}
										setPauseModal={setPauseModal}
										data={data}
										trackLoading={trackLoading}
										handlePauseActive={handlePauseActive}
										setPreviewState={setPreviewState}
										setActive={setActive}
										getTrackAndUpdateData={getTrackAndUpdateData}
									/>
								)}

								{activeTab == 2 && (
									<div className={stylestwo.analysis__wrapper}>
										<CompetitionReview
											isSingleRound={true}
											isShowActiveRoundsButton={true}
										/>
									</div>
								)}
								{activeTab == 3 && (
									<Configurations
										setUpdateSingleRound={setUpdateSingleRound}
										item={state?.singleRound}
									/>
								)}
							</div>
						</>
					}
				</>
			)}
		</>
	);
};

export default SingleRound;
