import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingState = () => {
	return (
		<div className='w-full h-full flex flex-col items-center justify-center gap-7'>
			<CircularProgress
				sx={{ color: 'var(--primary-text)', width: '60px', height: '60px' }}
			/>
			<h1 className='text-xs font-semibold text-center text-[#7B8DA2]'>
				Sharbo AI is gathering the freshest intelligence for you
			</h1>
		</div>
	);
};

export default LoadingState;
