import React from 'react';

const StackCard = ({ tech, date }) => {
	console.log(tech, date);
	return (
		<div className='w-full h-12 px-2 py-1 rounded border border-[#3d86dc] justify-center items-center gap-1 inline-flex flex-col text-center'>
			<span className='text-[#3d86dc]  text-xs font-normal tracking-tight text-nowrap text-ellipsis overflow-hidden w-full'>
				{tech}
			</span>
			<span className='text-[#7b8da2] text-[8px] font-normal tracking-tight'>
				Last Verified at: {date}
			</span>
		</div>
	);
};

export default StackCard;
