import React from "react";
import SectionCard from "../OverviewTab/SectionCard";
import { Agreement03Icon, InformationSquareIcon } from "@hugeicons/react";
import { useOutletContext } from "react-router-dom";
import NoData from "../NoData";

const AcquisitionsTab = () => {
  const { acquisition } = useOutletContext();
  const acquiredByDataPresent =
    acquisition?.ownership_status ||
    acquisition?.acquirer ||
    acquisition?.anounced_date ||
    acquisition?.price;
  const acquiredByData = [
    { label: "Ownership Status", data: acquisition?.ownership_status },
    { label: "Acquirer", data: acquisition?.acquirer },
    { label: "Announced Date", data: acquisition?.anounced_date },
    { label: "Price", data: acquisition?.price },
  ];

  return acquisition?.data ? (
    <div className="w-full h-auto xl:h-full flex flex-col-reverse xl:flex-row justify-between gap-y-6 ">
      {/* Left column */}
      <div className="w-full xl:w-3/5 h-full flex flex-col gap-5">
        {acquisition?.num_acquisitions && (
          <div className="w-full h-auto flex items-center gap-2">
            <Agreement03Icon
              size={18}
              color="#5092E0"
              variant="stroke"
              type="rounded"
            />
            <span className="text-[--dark-text] text-xs font-normal">
              Completed Company Acquisitions:
            </span>
            <span className="text-[--dark-text] text-4xl font-medium leading-relaxed">
              {acquisition?.num_acquisitions}
            </span>
          </div>
        )}
        {/* Show only if data is present */}
        {acquisition?.num_acquisitions > 0 ? (
          acquisition?.acquisitions?.map((item, index) => (
            <div
              key={"acq" + index}
              className="w-full rounded border border-[--tab-section-border] text-[--dark-text]"
            >
              {/* Header section */}
              <div className="w-full h-auto px-4 py-2.5 flex items-center justify-between border-b border-[--tab-section-border] rounded-t bg-[--active-card-title-bg]">
                <span className=" text-xs font-medium">
                  {item?.acquiree_name}
                </span>
                <span className="h-auto px-4 rounded-2xl bg-text-light/10 justify-center items-center gap-0.5 inline-flex text-[10px] leading-8 font-medium tracking-tight">
                  Announced date:{" "}
                  {item?.announced_date ?? "No intelligence available"}
                </span>
              </div>
              {/* Card body */}
              <div className="w-full h-auto  min-h-12 px-4 py-8 text-[--secondary-text] text-[10px] font-medium">
                <div
                  className="w-1/2 h-10 min-w-[200px] relative text-xs px-2.5 rounded leading-8 inline-flex items-center gap-1.5 border text-[--secondary-text] 
                    border-[--secondary-border] font-normal"
                >
                  Price:{" "}
                  {item?.price ? (
                    <span className="text-sm font-bold">
                      {item?.currency}
                      {item?.price}
                    </span>
                  ) : (
                    "No intelligence available"
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {/* RIght column */}
      {acquiredByDataPresent && (
        <div className="w-1/3 h-full flex flex-col gap-3">
          <div className="w-full text-xs text-[--dark-text] flex gap-1.5">
            <InformationSquareIcon
              size={14}
              color="#5092E0"
              variant="stroke"
              type="rounded"
            />
            Acquired By:
          </div>
          {acquiredByData.map((info) => (
            <SectionCard key={info.label} label={info.label} data={info.data} />
          ))}
        </div>
      )}
    </div>
  ) : (
    <NoData />
  );
};

export default AcquisitionsTab;
