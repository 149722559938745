import React, { useState } from "react";
import SectionCard from "../OverviewTab/SectionCard";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useOutletContext } from "react-router-dom";
import AgeProgressBar from "../../TargetCharts/AgeCharts";
import SexRatioChart from "../../TargetCharts/SexRatioCharts";
import CountryPieChart from "../../TargetCharts/CountryCharts";
import YearDropdown from "../../TargetCharts/YearDropdown";
import NoData from "../NoData";

const TooltipContent = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#1E1B39] px-5 py-2 rounded-md shadow-md flex flex-col gap-1 text-center text-white">
        <h1 className="text-xs text-center font-normal">Visitors</h1>
        <h1 className="text-sm text-center font-medium">
          {formatNumberWithSuffix(payload[0]?.value)}
        </h1>
      </div>
    );
  }
};

function formatNumberWithSuffix(num) {
  if (typeof num !== "number" || isNaN(num)) {
    throw new Error("Input must be a valid number.");
  }

  const absNum = Math.abs(num);
  let suffix = "";
  let formattedNum = num;

  if (absNum >= 1_000_000_000) {
    formattedNum = num / 1_000_000_000;
    suffix = "B";
  } else if (absNum >= 1_000_000) {
    formattedNum = num / 1_000_000;
    suffix = "M";
  } else if (absNum >= 1_000) {
    formattedNum = num / 1_000;
    suffix = "K";
  }

  return `${formattedNum.toLocaleString("en", {
    maximumFractionDigits: 2,
  })}${suffix}`;
}

const formatMonth = (month) => String(month).toUpperCase().substring(0, 3);

const TrafficTab = () => {
  const { website_traffic } = useOutletContext();
  const pricingData = [
    {
      label: "Global Rank",
      data: website_traffic?.global_rank,
    },
    {
      label: "Bounce Rate",
      data: website_traffic?.bounce_rate,
    },
    {
      label: "Average number of pages viewed per visit",
      data: website_traffic?.average_num_pages_viewed_per_visit,
    },
    {
      label: "Average Visit Duration",
      data: website_traffic?.average_visit_duration,
    },
  ];

  const [year, setYear] = useState(new Date().getFullYear());

  const groupDataByYear = (data) =>
    data?.reduce((acc, item) => {
      const { year, ...rest } = item; // Extract year and other data
      if (!acc[year]) acc[year] = []; // Initialize an array for the year if not exists
      acc[year].push(rest); // Push the rest of the data into the corresponding year
      return acc;
    }, {});

  const monthlyChart = groupDataByYear(
    website_traffic?.total_website_visits_by_month
  );

  return website_traffic?.data ? (
    <div className="w-full h-auto flex flex-col 2xl:flex-row justify-between gap-y-6 ">
      <div className="w-full 2xl:w-3/5 h-auto flex flex-col gap-10">
        <div className="w-full flex flex-row justify-between items-end">
          <div className="w-full h-auto flex flex-col justify-center gap-2">
            <p className="text-gray-500 text-xs font-normal">
              Monthly Website Visits
            </p>
            <div className="w-full flex flex-row gap-10 items-center">
              <p className="text-[--dark-text] text-2xl font-medium leading-relaxed">
                {formatNumberWithSuffix(website_traffic?.monthly_visits)}
              </p>
              <p className="text-green-400 text-xs font-normal">
                {website_traffic?.monthly_visits_change}%{" "}
                <span className="text-gray-500 font-light text-xs tracking-[1.6px]">
                  VS LAST MONTH
                </span>
              </p>
            </div>
          </div>
          <div className="w-auto h-auto flex flex-row gap-5 items-center pb-3">
            <YearDropdown year={year} setYear={setYear} data={monthlyChart} />
          </div>
        </div>
        {monthlyChart[year]?.length > 0 ? (
          <div className="w-full h-auto min-h-60 2xl:h-2/3">
            <ResponsiveContainer width="100%" height="90%" minHeight={240}>
              <LineChart
                width={500}
                height={300}
                data={monthlyChart[year]}
                margin={{
                  top: 16,
                  right: 30,
                  left: 16,
                  bottom: 5,
                }}
              >
                <XAxis
                  tickLine={false}
                  dataKey="date"
                  tickFormatter={formatMonth}
                  tickMargin={10}
                  tick={{ fill: "#615E83", fontSize: 12 }}
                  stroke="#E5E5EF"
                />
                <YAxis hide tickFormatter={formatNumberWithSuffix} />
                <Tooltip content={<TooltipContent />} />
                <Line
                  type="monotone"
                  dataKey="total_website_visits"
                  stroke="#0069E5"
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 6 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="w-full h-auto min-h-60 2xl:h-2/3">
            <NoData />
          </div>
        )}
        <div className="w-full flex flex-row gap-10">
          {Object.entries(website_traffic?.visits_by_age)?.length > 0 && (
            <div className="w-[35%]">
              <AgeProgressBar ageData={website_traffic.visits_by_age} />
            </div>
          )}
          {Object.entries(website_traffic?.visits_by_gender)?.length > 0 && (
            <div className="w-[20%] ">
              <SexRatioChart rawData={website_traffic.visits_by_gender} />
            </div>
          )}
          {website_traffic?.visitors_by_country?.length > 0 && (
            <div className="w-[35%] h-auto flex flex-row rounded border border-[--secondary-border]">
              <CountryPieChart data={website_traffic.visitors_by_country} />
            </div>
          )}
        </div>
      </div>
      <div className="w-full 2xl:w-1/3 h-auto flex flex-col gap-3">
        {pricingData.map((info) => (
          <SectionCard key={info.label} label={info.label} data={info.data} />
        ))}
        <div className="flex-row w-full relative max-h-[300px] pb-20 px-2.5 rounded border border-[--secondary-border] text-[--secondary-text] justify-start items-center content-start gap-x-3.5 inline-flex flex-wrap">
          <span className="text-nowrap w-auto text-xs font-normal leading-9">
            Top Topics:
          </span>
          {website_traffic?.top_topics?.length > 0 ? (
            website_traffic.top_topics.map((item) => (
              <span className="border px-3  rounded-md text-nowrap w-auto text-xs font-medium leading-5 text-right">
                {item}
              </span>
            ))
          ) : (
            <span className="text-nowrap w-auto text-xs font-normal leading-9">
              No intelligence available
            </span>
          )}
        </div>
      </div>
    </div>
  ) : (
    <NoData />
  );
};

export default TrafficTab;
