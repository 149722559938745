import {
	ListViewIcon,
	SaveMoneyDollarIcon,
	UserListIcon,
} from '@hugeicons/react';
import React from 'react';
import SectionCard from '../../Components/Target/OverviewTab/SectionCard';
import NoData from '../../Components/Target/NoData';

const WorkforcePage = ({ data }) => {
	const additionalData = [
		{
			label: 'Average score of employee reviews',
			data: data?.workforce?.additional?.avg_score_employee_reviews,
		},
		{
			label: 'Current CEO approval score',
			data: data?.workforce?.additional?.current_ceo_approval_score,
		},
		{
			label: 'Current senior management score',
			data: data?.workforce?.additional?.current_senior_management_score,
		},
		{
			label: 'Compensation and benefits rating',
			data: data?.workforce?.additional?.compensation_benefits_rating,
		},
		{
			label: 'Business outlook rating:',
			data: data?.workforce?.additional?.business_outlook_rating,
		},
	];
	return (
		<div className='flex flex-col w-full h-[1056px]  justify-start items-center bg-black relative px-16 py-24'>
			<div className='text-white flex flex-col justify-start items-start gap-3 w-full'>
				<h3 className='text-white font-bold text-[60px]'>WORKFORCE</h3>
				{data?.workforce.employees_count_breakdown_by_department &&
				Object?.entries(
					data?.workforce?.employees_count_breakdown_by_department
				)?.length > 0 ? (
					<div className='w-full flex flex-col gap-1.5 flex-grow overflow-hidden  rounded border border-[--tab-section-border]'>
						<div className='w-full h-auto flex justify-start gap-1.5 items-center pl-6 pr-10 pt-5'>
							<UserListIcon
								size={14}
								color='#5092E0'
								variant='stroke'
								type='rounded'
							/>
							<h3 className='text-[--dark-text] text-xs font-medium'>
								Number of Employees:
							</h3>
							<h2 className='text-[--dark-text] text-xl font-medium leading-relaxed'>
								{data?.workforce?.employees_count}
							</h2>
						</div>
						<div className='w-full flex-grow flex flex-col gap-2.5 py-5 pl-6 pr-10 overflow-y-scroll '>
							<div className='w-full flex justify-start items-center text-[10px] text-[--dark-text]'>
								<div className='w-1/3 px-5'>Type</div>
								<div className='w-2/3 px-5'>Pricing</div>
							</div>
							{data?.workforce &&
								Object?.entries(
									data?.workforce?.employees_count_breakdown_by_department
								)
									?.slice(0, 3)
									.map((item, index) => (
										<div className='w-full flex justify-start items-center text-xs leading-6 text-[--dark-text] rounded border border-[--tab-section-border] py-[7px] divide-x divide-[--tab-section-border]'>
											<div className='w-1/3 px-5'>{item[0]}</div>
											<div className='w-2/3 px-5 text-sm leading-6'>
												{item[1]}
											</div>
										</div>
									))}
						</div>
					</div>
				) : (
					<div className='w-full h-full min-h-[400px]'>
						<NoData />
					</div>
				)}
				{data?.workforce?.active_job_postings?.data && (
					<div className='w-full h-auto'>
						<div className='w-full h-auto flex justify-start gap-1.5 items-center'>
							<ListViewIcon
								size={14}
								color='#5092E0'
								variant='stroke'
								type='rounded'
							/>
							<h3 className='text-[--dark-text] text-xs font-medium'>
								Active Job Postings:
							</h3>
							<h2 className='text-[--dark-text] text-xl font-medium leading-relaxed'>
								{data?.workforce?.active_job_postings?.count}
							</h2>
						</div>
						<div className="w-full max-h-24 mt-1 p-3.5 rounded border border-[--secondary-border] text-[--secondary-text] text-[10px] font-medium font-['Inter'] overflow-y-auto">
							{data?.workforce?.active_job_postings?.job_postings ??
								'No intelligence available'}
						</div>
					</div>
				)}
				{data?.workforce?.additional?.data &&
					data?.workforce?.base_salary?.data && (
						<div className='w-full xl:w-1/3 h-full flex flex-col gap-3'>
							{data?.workforce?.additional?.data && (
								<>
									<div className='w-full text-xs text-[--dark-text]'>
										Additional Intelligence:
									</div>
									{additionalData.map((info) => (
										<SectionCard
											key={info.label}
											label={info.label}
											data={info.data}
										/>
									))}
								</>
							)}
							{data?.workforce?.base_salary?.data && (
								<div className='w-full max-h-[300px] xl:max-h-[auto] flex flex-col gap-1.5 xl:flex-grow overflow-hidden px-3 py-4 rounded border border-[--tab-section-border]'>
									<div className='w-full h-auto flex justify-start gap-1.5 items-center'>
										<SaveMoneyDollarIcon
											size={14}
											color='#5092E0'
											variant='stroke'
											type='rounded'
										/>
										<h3 className='text-[--dark-text] text-xs font-medium'>
											Base Salary
										</h3>
									</div>
									<div className='w-full flex-grow flex flex-col gap-2.5 py-5 overflow-y-scroll '>
										<div className='w-full flex justify-start items-center text-[10px] text-[--dark-text]'>
											<div className='w-full px-5'>Title</div>
											<div className='w-1/4 px-5'>Median Salary</div>
											<div className='w-1/4 px-5'>Pay Period</div>
										</div>
										{data?.workforce?.base_salary?.base_salary_data?.map(
											(item, index) => (
												<div
													key={'salary' + index}
													className='w-full flex justify-start items-center text-xs leading-6 text-[--dark-text] rounded border border-[--tab-section-border] py-[7px] divide-x divide-[--tab-section-border]'>
													<div className='w-full px-5'>{item.title}</div>
													<div className='w-1/4 px-5 text-sm leading-6'>
														{item.salary_median}
													</div>
													<div className='w-1/4 px-5'> {item?.pay_period}</div>
												</div>
											)
										)}
									</div>
								</div>
							)}
						</div>
					)}
			</div>
		</div>
	);
};

export default WorkforcePage;
