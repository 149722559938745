import {
  ArrowDown01Icon,
  ArrowRight01Icon,
  ArrowUp01Icon,
  ChartRadarIcon,
  FlowSquareIcon,
  Radar01Icon,
  Settings03Icon,
  ThreeDScaleIcon,
} from "@hugeicons/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarList = () => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(
    location.pathname.includes("/round") ||
      location.pathname.includes("/foundation")
  );

  useEffect(() => {
    setDropdownOpen(
      location.pathname.includes("/round") ||
        location.pathname.includes("/foundation")
    );
  }, [location?.pathname]);
  const menuIconProps = {
    variant: "stroke",
    size: 16,
    type: "rounded",
    color: "currentColor",
  };

  const menu = useMemo(() => {
    return [
      {
        key: "targets",
        to: "/",
        label: "Targets",
        type: "link",
        icon: <Radar01Icon {...menuIconProps} />,
        open:
          location.pathname === "/" || location.pathname.includes("/target"),
      },
      {
        key: "comparison",
        label: "Product Comparisons",
        type: "dropdown",
        icon: <FlowSquareIcon {...menuIconProps} />,
        open:
          location.pathname.includes("/round") ||
          location.pathname.includes("/foundation"),
        dropdown: [
          {
            key: "rounds",
            to: "/rounds",
            label: "Rounds",
            type: "link",
            icon: <ChartRadarIcon {...menuIconProps} />,
            open: location.pathname.includes("/round"),
          },
          {
            key: "foundations",
            to: "/foundations",
            label: "Foundations",
            type: "link",
            icon: <ThreeDScaleIcon {...menuIconProps} />,
            open: location.pathname.includes("/foundation"),
          },
        ],
      },
      {
        key: "settings",
        to: "/settings",
        label: "Settings",
        type: "link",
        icon: <Settings03Icon {...menuIconProps} />,
        open: location.pathname.includes("/setting"),
      },
    ];
  }, [location?.pathname]);

  return (
    <div className="px-5 pt-2.5 pb-6 border-b-[0.5px] border-[--light-border] w-full relative block font-semibold  transition-all duration-300 ">
      {menu.map((item) =>
        item?.type == "link" ? (
          <Link
            to={item.to}
            key={item.key}
            className={`w-full h-14 relative flex items-center justify-between gap-3 mb-1.5 py-4 px-2.5 hover:bg-[--active-sidebar-bg] ${
              item?.open ? "bg-[--active-sidebar-bg]" : ""
            } rounded text-sm`}
          >
            <span className="flex items-center gap-3">
              <span
                className={
                  !item?.open
                    ? `text-[#A0A8BE] dark:text-[#474C5C]`
                    : "text-[--primary-blue]"
                }
              >
                {item?.icon}
              </span>
              <span
                className={
                  !item?.open
                    ? `grow text-[--dark-text]`
                    : "grow text-[--primary-blue]"
                }
              >
                {item?.label}
              </span>
            </span>
            {item?.open && (
              <ArrowRight01Icon variant="stroke" size={18} color="#0069E5" />
            )}
          </Link>
        ) : (
          <Fragment key={item?.key}>
            <button
              onClick={() => setDropdownOpen((prev) => !prev)}
              className={`w-full h-14 relative flex items-center justify-between gap-3 mb-1.5 py-4 px-2.5 hover:bg-[--active-sidebar-bg] ${
                item?.open || dropdownOpen ? "bg-[--active-sidebar-bg]" : ""
              } rounded text-sm`}
            >
              <span className="flex items-center gap-3">
                <span
                  className={
                    !(item?.open || dropdownOpen)
                      ? `text-[#A0A8BE] dark:text-[#474C5C]`
                      : "text-[--primary-blue]"
                  }
                >
                  {item?.icon}
                </span>
                <span
                  className={
                    !(item?.open || dropdownOpen)
                      ? `grow text-[--dark-text]`
                      : "grow text-[--primary-blue]"
                  }
                >
                  {item?.label}
                </span>
              </span>
              {item?.open || dropdownOpen ? (
                <ArrowDown01Icon variant="stroke" size={18} color="#0069E5" />
              ) : (
                <ArrowUp01Icon variant="stroke" size={18} color="#5D7591" />
              )}
            </button>
            {(item?.open || dropdownOpen) &&
              item?.dropdown.map((subItem) => (
                <Link
                  to={subItem?.to}
                  key={subItem?.key}
                  className={`w-full h-14 relative flex items-center justify-between gap-3 mb-1.5 py-4 pl-8 pr-2.5 hover:bg-[--active-sidebar-bg] ${
                    subItem?.open ? "bg-[--active-sidebar-bg]" : ""
                  } rounded text-sm`}
                >
                  <span className="flex items-center gap-3">
                    <span
                      className={
                        !subItem?.open
                          ? `text-[#A0A8BE] dark:text-[#474C5C]`
                          : "text-[--primary-blue]"
                      }
                    >
                      {subItem?.icon}
                    </span>
                    <span
                      className={
                        !subItem?.open
                          ? `grow text-[--dark-text]`
                          : "grow text-[--primary-blue]"
                      }
                    >
                      {subItem?.label}
                    </span>
                  </span>
                  {subItem?.open && (
                    <ArrowRight01Icon
                      variant="stroke"
                      size={18}
                      color="#0069E5"
                    />
                  )}
                </Link>
              ))}
          </Fragment>
        )
      )}
    </div>
  );
};

export default SidebarList;
